import React from 'react'
import Footer from '../Footer'

const PaymentPolicy = () => {
    return (
        <>
            <section>
                <div className="container my-5">
                    <h2 className="mt-4 text-center">Payment Policy</h2>
                    <p>At Kataria, we understand the importance of a smooth and secure payment experience for our customers. To ensure your comfort and flexibility, we offer a variety of trusted payment options to suit your needs:</p>

                    <h3>1. Cash on Delivery (COD):</h3>
                    <ul>
                        <li>We do have Cash On Delivery payment option only applicable within INDIA.</li>
                        <li>Cash On Delivery is only applicable for orders below INR 5,000.00.</li>
                        <li>For orders above INR 5,000.00, we can help you with our bank account details or send you a payment invoice. Payment can be made through your Debit / Credit card.</li>
                        <li>We do not do any alterations or customizations under the Cash On Delivery payment option.</li>
                        <li>We do Cash On Delivery only through Blue Dart & FedEx Express Company to selected pin codes. We request you to confirm the pin code with us through our helpline number (+919321032490), email us at contact@katariasstore.com, or chat with us through our website.</li>
                        <li>Cash On Delivery orders are confirmed by us through email or call and will take the confirmation accordingly within 2 business days.</li>
                    </ul>

                    <h3>2. Debit & Credit Card Payments:</h3>
                    <ul>
                        <li>Securely pay with Visa, Mastercard, and other major cards.</li>
                        <li>Fast and convenient checkout experience.</li>
                        <li>Verified and encrypted transactions for your peace of mind.</li>
                    </ul>

                    <h3>3. Bank Transfer:</h3>
                    <ul>
                        <li>Receive our bank account details upon order confirmation.</li>
                        <li>Transfer the invoice amount and confirm to complete your purchase.</li>
                        <li>Suitable for large orders exceeding INR 5,000.</li>
                    </ul>

                    <h3>4. Secure Payment Gateway:</h3>
                    <ul>
                        <li>All online transactions go through a secure payment gateway for maximum protection.</li>
                        <li>Advanced fraud detection systems safeguard your financial information.</li>
                        <li>We never store your credit card details on our servers.</li>
                    </ul>

                    <h3>Additional Information:</h3>
                    <ul>
                        <li>All prices listed on our website are inclusive of taxes and duties.</li>
                        <li>We offer secure and verified invoices upon purchase completion.</li>
                        <li>For any payment-related queries, please contact our customer support team.</li>
                    </ul>

                    <h3>Remember:</h3>
                    <ul>
                        <li>Choose the payment method that best suits your needs and preferences.</li>
                        <li>Ensure you have sufficient funds available for your chosen payment method.</li>
                        <li>Review your order details carefully before confirming your purchase.</li>
                        <li>Dispatch time will be 7-10 days depending on the product.</li>
                        <li>Products which fall under made-to-measure and customization categories are not applicable for refund or exchange.</li>
                    </ul>

                    <p>Enjoy a safe and convenient shopping experience with Kataria's flexible payment options!</p>
                </div>
            </section>
            <Footer /> 
        </>
    )
}

export default PaymentPolicy