import React, { useEffect, useMemo, useState } from 'react';
import "../Order/MyOrder.css";
// import ProductImage01 from "../../Images/Products/Basket-PNG-Image-HD.png"
import { HiStar } from 'react-icons/hi';
import { useCartContext } from '../../context/addToCart';
import { RxCross2 } from "react-icons/rx";
import { Drawer, Form, Input, message, Modal, Rate } from 'antd';
import { Button, ConfigProvider, Popover, Segmented } from 'antd';
import axios from 'axios';
import { Pagination } from 'antd';
import moment from 'moment';
import { get, post } from "../../apiService";

const MyOrder = () => {

    const { cartValue } = useCartContext();
    const tokenid = localStorage.getItem("access_token")
    const profile = JSON.parse(localStorage.getItem('profile'))
    const [reviewModal, setReviewModal] = useState(false)
    const [isReviewExisting, setIsReviewExisting] = useState(false)
    const [rating, setRating] = useState(0);
    const [form] = Form.useForm()
    console.log(profile);
    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        setReviewModal(false);
    };
    const [open, setOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const showDrawer = (order) => {
        setOrderDetails(order);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const Content = ({ address_1, address_2, state, city, pincode }) => (
        <div className='Order-Details-Address'>
            <p>{address_1}</p>
            <p>{address_2}</p>
            <p>{city}, {state} {pincode}</p>
        </div>
    );
    // const text = <span>Title</span>;
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);


    const [yourOrderProduct, setYourOrderProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [totalItems, setTotalItems] = useState(0);   // Track total number of items
    const [productID, setProductID] = useState(null)
    const itemsPerPage = 4; // Number of items per page


    console.log("yourOrderProduct", yourOrderProduct)
    // console.log('currentPage '+ currentPage);

    const fetchData = async (page) => {
        try {
            const response = await get(`/orders.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&page=${page}&limit=${itemsPerPage}&user_id=${profile.user_id}`);
            setYourOrderProduct(response.list);
            setTotalItems(response.total); // Assuming response contains total number of items
            console.log("Fetched data:", response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch data when component mounts and when currentPage changes
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    // const onFinish = async (values) => {
    //     console.log(values);
    //     if (!profile?.user_id) {
    //         message.error('Please Login First.');
    //         return;
    //     }
    //     const formData = new FormData();
    //     setLoading(true); // Start loading

    //     try {
    //         // Append form data
    //         formData.append('review', values.reviewContent);
    //         formData.append('stars', values.rating);
    //         formData.append('action', 'add');
    //         formData.append('user_id', profile.user_id);
    //         formData.append('product_id', productID);
    //         formData.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH')

    //         // Simulate API request
    //         const response = await post('/review.php', formData); // Replace with your actual API endpoint
    //         if (response) {
    //             // Handle success response
    //             message.success('Review submitted successfully')
    //             console.log('Review submitted successfully:', response);
    //         }
    //     } catch (error) {
    //         console.log('Error posting review:', error);
    //     } finally {
    //         setLoading(false); // Stop loading after submission
    //         // handleOk(); // Close the modal
    //         setReviewModal(false)
    //     }
    // }
    const onFinish = async (values) => {
        if (!profile?.user_id) {
            message.error('Please Login First.');
            return;
        }

        const formData = new FormData();
        setLoading(true);

        try {
            formData.append('review', values.reviewContent);
            formData.append('stars', values.rating);
            formData.append('user_id', profile.user_id);
            formData.append('product_id', productID);
            formData.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');

            if (isReviewExisting) {
                // Send request to update the existing review
                formData.append('action', 'update');
                formData.append('review_id', values.reviewId);
                const response = await post('/review.php', formData);
                if (response) {
                    message.success('Review updated successfully');
                }
            } else {
                // Send request to add a new review
                formData.append('action', 'add');
                const response = await post('/review.php', formData);
                if (response) {
                    message.success('Review submitted successfully');
                }
            }
        } catch (error) {
            console.error('Error submitting review:', error);
        } finally {
            setLoading(false);
            setReviewModal(false);
        }
    };
    const handleReviewModal = async (product_id) => {
        setProductID(product_id);

        try {
            // Fetch review data for this product and user
            const response = await get(`/review.php?action=fetch&user_id=${profile.user_id}&product_id=${product_id}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`);

            if (response && response.status === 'Success') {
                // If a review exists, auto-fill the form
                form.setFieldsValue({
                    rating: response?.data[0]?.stars,
                    reviewContent: response?.data[0]?.reviews,
                    reviewId: response?.data[0]?.review_id
                });
                setIsReviewExisting(true); // Set flag to show "Update Review"
            } else {
                // Reset form if no review exists
                form.resetFields();
                setIsReviewExisting(false);
            }

            setReviewModal(true);
        } catch (error) {
            console.error('Error fetching review:', error);
        }
    };

    // Logic to paginate the orders
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = yourOrderProduct && yourOrderProduct.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <section className='MyOrder-Section'>
                <div className='MyOrder-Section-Heading'>
                    <h1>All Order</h1>
                </div>

                <div className='MyOrder-Section-con'>
                    {yourOrderProduct === null ? (
                        <p>Looks like you haven't placed an order</p>
                    ) : (
                        <>
                            {yourOrderProduct && yourOrderProduct.map((elem, index) => {

                                const { User_Email, user_name, User_Phone, address, address_2, address_id, alternative_number, city, state, created_at, amount, order_id, order_list_id, status, payment_mode, pincode, products, tracking_id, user_id
                                } = elem
                                return (
                                    <>
                                        <div className='Product-addedCart-Side-Bar orderlistmaincon'>
                                            <div className='Orderlist-Details-Head-Con'>
                                                <div className='row'>
                                                    <div className='col-md-2 col-sm-4 col-12'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Order Placed</h2>
                                                            <h3>{moment(created_at).format("llll")}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 col-sm-4 col-6'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Total</h2>
                                                            <h3>{amount}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-sm-4 col-6'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Ship To</h2>
                                                            <h3>
                                                                <Popover placement="bottom" content={<Content address_1={address} address_2={address_2} city={city} state={state} pincode={pincode} />} arrow={mergedArrow}>
                                                                    <Button>{user_name}</Button>
                                                                </Popover>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div className='OrderList-Details-Date orderidcon'>
                                                            <h2>ORDER {order_id}</h2>
                                                            <div className='OrderList-Status-Details'>
                                                                <div className='OrderList-Status-Details-btn'>
                                                                    {status}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                products && products.map((elem) => {
                                                    const { Category_Id, Product_Code, Product_Desc, Product_Height, Product_Id, product_name, Product_Width, Size_Id, color_code, color_name, created_at, product_color_id, product_feature, product_price, product_qty, image, product_id
                                                    } = elem
                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details orderList-con'>
                                                                <div className='Product-details-addedCart-price-name order-details-addedCart-price-name'>
                                                                    <h3><span>Name : </span>{product_name}</h3>
                                                                    <h3><span>Quantity : </span>{product_qty}</h3>
                                                                    <h3><span>Price : </span>{product_price}/- Rs</h3>
                                                                </div>
                                                                {status === 'success' && (
                                                                    <div className='Product-details-addedCart-cancel-btn-con OrderProduct-Btn-on'>
                                                                        <button onClick={() => handleReviewModal(product_id)}>
                                                                            {/* <p> */}
                                                                            <div>
                                                                                <Rate />
                                                                            </div>
                                    
                                                                            {/* </p> */}
                                                                            {/* {isReviewExisting ? 'Update Review' : 'Review'} */}
                                                                        </button>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='Product-details-addedCart-cancel-btn'>
                                                <div className='Product-details-addedCart-cancel-btn-con OrderProduct-Btn-Con'>
                                                    <button onClick={() => showDrawer(elem)}>
                                                        View order details
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </>
                    )}
                    <Pagination
                        current={currentPage} // Current active page
                        total={totalItems}    // Total number of items from the API
                        pageSize={itemsPerPage} // Items per page
                        onChange={(page) => setCurrentPage(page)} // Change page
                    />
                </div>
            </section>

            <Drawer title="Order Details" onClose={onClose} open={open}>
                {orderDetails && (
                    <section className='Order-Details-Drawer'>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>Ordet Date</h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>{moment(orderDetails.created_at).format("llll")}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>order </h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>{orderDetails.order_id}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>Ordet total</h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>Rs. {orderDetails.amount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Shipment details</h2>
                            </div>
                            {
                                orderDetails.products && orderDetails.products.map((elem) => {
                                    const { Category_Id, Product_Code, Product_Desc, Product_Height, Product_Id, product_name, Product_Width, Size_Id, color_code, color_name, created_at, product_color_id, product_feature, product_price, product_qty, image } = elem
                                    return (
                                        <>
                                            <div className='Order-details-Drawer-Shipment-details'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className='Order-details-Drawer-Product-Image'>
                                                            <img src={image} />
                                                            {/* <img src={image[0].product_image} /> */}
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='Order-details-Drawer-Product-Details'>
                                                            <h3>{product_name}</h3>
                                                            <h4>Qty: <span>{product_qty}</span></h4>
                                                            <h4>Price: <span>{product_price}</span></h4>
                                                            <h4>color: <span>{color_name}</span></h4>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Payment Methods</h2>
                                <p>Online</p>
                            </div>
                            <div className='Order-details-Drawer-Shipment-details'>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <h3>{orderDetails.user_name}</h3>
                                    <p>{orderDetails.address}</p>
                                    {/* <p>{orderDetails.address_2}</p> */}
                                    <p>{orderDetails.city}, {orderDetails.state}, {orderDetails.pincode}</p>
                                </div>
                            </div>
                        </div>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Order Summary</h2>
                            </div>
                            <div className='Order-details-Drawer-Shipment-details'>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Subtotal:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.subtotal}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Delivery Charges:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.delivery_charges}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Tax:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.tax_amount}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3 className='Order-details-Drawer-OrderTotal'>Total Amount:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4 className='Order-details-Drawer-Orderamount'>{orderDetails.amount}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Drawer>
            <Modal
                open={reviewModal}
                title={isReviewExisting ? 'Update Your Review' : 'Write a Review'}
                onOk={() => form.submit()}
                confirmLoading={loading}
                onCancel={handleCancel}
            >
                <div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="rating"
                            label="Rating"
                            rules={[{ required: true, message: 'Please provide a rating' }]}
                        >
                            <Rate onChange={(value) => setRating(value)} value={rating} />
                        </Form.Item>
                        <Form.Item
                            name="reviewContent"
                            label="Review"
                            rules={[{ required: true, message: 'Please share your experience' }]}
                        >
                            <Input.TextArea rows={6} placeholder="How was your overall experience?" />
                        </Form.Item>

                        <Form.Item name="reviewId" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                    </Form>
                </div>
            </Modal>
            {/* <Modal
                open={reviewModal}
                title="Write a Review"
                onOk={() => form.submit()} // Trigger form submission when OK is clicked
                confirmLoading={loading} // Show loading spinner on the "OK" button
                onCancel={handleCancel}
            >
                <div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish} // Handle form submission
                    >
                        <Form.Item
                            name="rating"
                            label="Rating"
                            rules={[{ required: true, message: 'Please provide a rating' }]}
                        >
                            <Rate onChange={(value) => setRating(value)} value={rating} />
                        </Form.Item>
                     
                        <Form.Item
                            name="reviewContent"
                            label="Review"
                            rules={[{ required: true, message: 'Please share your experience' }]}
                        >
                            <Input.TextArea rows={6} placeholder="How was your overall experience?" />
                        </Form.Item>
                    </Form>
                </div>
            </Modal> */}
        </>
    )
}

export default MyOrder;