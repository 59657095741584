import React from 'react'

const Breadcrumbs = () => {
  return (
    <>
          <nav className="breadcrumbs" aria-label="Breadcrumb">
  <div className="container" bis_skin_checked={1}>
    <ol
      className="items flex flex-wrap pt-4 pb-4 text-15 text-black leading-18 tracking-tightest"
      itemScope=""
      itemType="https://schema.org/BreadcrumbList"
    >
      <li
        className="item flex home"
        itemProp="itemListElement"
        itemScope=""
        itemType="https://schema.org/ListItem"
      >
        {" "}
        <a
          itemScope=""
          itemType="https://schema.org/WebPage"
          href=""
          itemProp="item"
          itemID=""
          className="no-underline"
          title="Go to Home Page"
        >
          <span itemProp="name">Home</span>
        </a>{" "}
        <span itemProp="position" content={1} />
      </li>
      <li
        className="item flex category2016"
        itemProp="itemListElement"
        itemScope=""
        itemType="https://schema.org/ListItem"
      >
        {" "}
        <span aria-hidden="true" className="text-B2ADAD px-1.5">
          /
        </span>{" "}
        <a
          itemScope=""
          itemType="https://schema.org/WebPage"
          href="ethnic.html"
          itemProp="item"
          itemID="ethnic.html"
          className="no-underline"
          title=""
        >
          <span itemProp="name">Product</span>
        </a>{" "}
        <span itemProp="position" content={2} />
      </li>
      {/* <li
        className="item flex category2768"
        itemProp="itemListElement"
        itemScope=""
        itemType="https://schema.org/ListItem"
      >
        {" "}
        <span aria-hidden="true" className="text-B2ADAD px-1.5">
          /
        </span>{" "}
        <span itemProp="name">Mens - New Arrival</span>{" "}
        <span itemProp="position" content={3} />
      </li> */}
    </ol>
  </div>
</nav>
    </>
  )
}

export default Breadcrumbs