import React from 'react'
import "../Css/RecommendationCard.css";

const RecommendationCard = ({id, name,price,img}) => {
    return (
        <>
            <div className="product" bis_skin_checked={1}>
                <a
                    className="product-link"
                    href="/in/mustard-yellow-lehariya-printed-kurta-set-for-men.html"
                    target="_blank"
                >
                    <span className="product-image-container">
                        <img
                            className="product-image"
                            // data-src="https://newcdn.kalkifashion.com/media/tagalys/product_images/m/u/mustard_yellow_lehariya_printed_kurta_set_for_men-sg235780_4_.jpg"
                            alt="Mustard Yellow Lehariya Printed Kurta Set For Men"
                            src={img}
                        />
                    </span>
                    <div className="product-details" bis_skin_checked={1}>
                        <span className="recommendation-product-name my-2">
                            {name}
                        </span>
                        <span className="product-prices full-price d-block">
                            <span className="product-price-regular fw-semibold">₹{price}</span>
                        </span>
                    </div>
                </a>
            </div>

        </>
    )
}

export default RecommendationCard