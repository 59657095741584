import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import "../Css/CircleComp.css";
import { Link } from "react-router-dom";

const CircleComp = ({ category }) => {
  console.log(category);
  return (
    <>
      <section className="home-our-categories">
        <div className="container">
          <div className="home-our-categories-section-head">
            <h4 className="text-center text-under-line"> Our categories</h4>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {category?.map((v, i) => (
              <SwiperSlide>
                <Link to={`/product/${v.category_slugs}`}>
                  <div className="main-circle">
                    <div className="inner-circle">
                      <img className="img-circle" alt="" src={v.category_img} />
                    </div>
                    <div className="text-center mt-3">{v.category_name}</div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default CircleComp;
