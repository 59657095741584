import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Drawer, Space, Badge, Divider, Empty, Dropdown, ConfigProvider, Popover } from 'antd';
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import "../Css/Header.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../Assert/logo.png";
import { FaLinkedinIn, FaPinterestP, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { GoHeart } from "react-icons/go";
import { GiBeachBag } from "react-icons/gi";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { IoBag } from "react-icons/io5";
import ShoopingCart from './ShoopingCart';
import { useCartContext } from '../context/addToCart';
import { getProfile, getToken } from "../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../features/authSlice";


const items = [
    {
        key: '1',
        label: (
            <Link to='/account/sign-in'>
                sign in
            </Link>
        ),
    },
    {
        key: '2',
        label: (
            <Link to='/account/sign-up'>
                sign up
            </Link>
        ),
    },
];


const Header = () => {
    // const {cartCount } = useCartContext();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const { access_token } = getToken();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { cartValue, setCartCount, setCartValue, cartCount } = useCartContext();
    const { profile } = getProfile()
    const [visibleCategories, setVisibleCategories] = useState(5);
    const [getCat, setGetCat] = useState([])
    const navigate = useNavigate()
    const showDrawer = () => {
        setOpen(true);
    };

    const handleLogout = () => {
        setVisible(false)
        localStorage.clear();
        localStorage.removeItem('category')
        sessionStorage.removeItem('bis_data')
        sessionStorage.clear()
        navigate('/')
    }
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const onClose = () => {
        setOpen(false);
    };
    const [opens, setOpens] = useState(false);
    const showDrawers = () => {
        setOpens(true);
    };
    const onCloses = () => {
        setOpens(false);
    };
    const [visible, setVisible] = useState(false);

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    const handleClosePopover = () => {
        setVisible(false);
    };
    const removeCartData = (index) => {
        setCartValue((prevData) => {
            const updatedData = [...prevData];
            updatedData.splice(index, 1);
            addtoLocatStorage(updatedData);
            setCartCount(updatedData.length);
            return updatedData;
        });
    };
    const addtoLocatStorage = (data) => {
        localStorage.setItem("cart", JSON.stringify(data));
    };
    // const getCategory 
    useEffect(() => {
        setGetCat(JSON.parse(localStorage.getItem('category')))

    }, [])
    useLayoutEffect(() => {
        // setGetCat(JSON.parse(localStorage.getItem('category')))
        //   return () => {
        //     second
        //   };
    }, [])

    useEffect(() => {
        dispatch(setUserToken({ token: access_token }));
        // const handleResize = () => {
        //     setIsMobile(window.innerWidth < 768);
        // };

        // window.addEventListener('resize', handleResize);
        // return () => window.removeEventListener('resize', handleResize);
    }, [access_token, dispatch]);

    console.log(getCat);
    const content = (
        <div className="popover-content-custom">
            <div>
                <Link to="/profiles" onClick={handleClosePopover}>Profile</Link>
            </div>
            <div onClick={handleLogout}>
                <Link>
                    Logout
                </Link>
            </div>
        </div>
    );

    const handleLoadMore = () => {
        setVisibleCategories(prevVisible => prevVisible + 5); // Show 5 more categories
    };
    return (
        <>
            <div className='outter-navbar'>
                <div className='container navbar-main-container'>
                    <div>
                        <Space>
                            <Button className='header-drawer' type="" onClick={showDrawer}>
                                <RxHamburgerMenu />
                            </Button>
                            <Drawer
                                title=""
                                placement={placement}
                                width={500}
                                onClose={onClose}
                                open={open}


                            >
                                {!profile && (
                                    <Link to='/account/sign-in' className='header-drawer-sign-in-sign-out' onClick={onClose}>
                                        <div className='d-flex justify-content-start gap-3 align-items-center mb-2'>
                                            <label>
                                                <FaUserCircle className='fs-5' />

                                            </label>
                                            <label className='fs-5 fw-bold'>
                                                Login
                                            </label>
                                        </div>
                                        <Divider />
                                    </Link>
                                )}
                                <h5 className='fw-'>
                                    <Link to='/product/all' onClick={onClose}>
                                        All Products
                                    </Link>
                                </h5>
                                <Divider />
                                <h5>Products Category</h5>
                                <Divider />
                                <div className='d-flex navbar-product-category'>

                                    <div>
                                        {getCat && getCat?.length > 0 && getCat?.slice(0, visibleCategories).map((v, i) => (
                                            <div key={i}>
                                                <Link to={`/product/${v.category_slugs}`} onClick={onClose}>
                                                    {v.category_name}
                                                </Link>
                                            </div>
                                        ))}
                                        {visibleCategories < getCat.length && (
                                            <button className='fw-bold fs-6' onClick={handleLoadMore}>Load More</button>
                                        )}
                                    </div>
                                    {/* <div>
                                        Sherwani
                                    </div>
                                    <div>
                                        Jodhpuri
                                    </div>
                                    <div>
                                        Kurta sets
                                    </div>
                                    <div>
                                        shalwar kameez
                                    </div> */}

                                </div>

                                <Divider />
                                <h5 className=''>Useful Links</h5>
                                <Divider />
                                <div className='d-flex navbar-product-category'>
                                    <div>
                                        <Link to="/About-us" onClick={onClose}>
                                            About us
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/contact-page" onClick={onClose}>
                                            Contact us
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to='/TermsofService' onClick={onClose}>
                                            Terms & Conditions
                                        </Link>
                                    </div>
                                    {/* <div>
                                        FAQ's
                                    </div> */}
                                    <div>
                                        <Link to='/PrivacyPolicie' onClick={onClose}>
                                            Privacy Policy
                                        </Link>
                                    </div>
                                </div>
                                <Divider />
                                <div className='navbar-social-media'>
                                    <div className='navbar-social-media-inner-div'>
                                        <div>
                                            <a href='https://www.facebook.com/' target='_blank'>

                                                <FaFacebookF />
                                            </a>

                                        </div>
                                        <div>
                                            <a href='https://www.instagram.com/' target='_blank'>
                                                <TiSocialInstagram />
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://x.com/?lang=en' target='_blank'>
                                                <FaTwitter />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://www.youtube.com/" target='_blank'>
                                                <FaYoutube />
                                            </a>

                                        </div>
                                        <div>
                                            <a href="https://in.pinterest.com/" target='_blank'>

                                                <FaPinterestP />
                                            </a>

                                        </div>
                                        <div>
                                            <a href="https://www.linkedin.com/" target='_blank'>
                                                <FaLinkedinIn />

                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        </Space>
                    </div>
                    <div className='nav-logo-div'>
                        <Link to='/'>
                            <img src={logo} />
                        </Link>
                    </div>
                    <div className='navbar-right-main'>
                        {/* <div className="searchproductpage" bis_skin_checked={1}>
                            <div className="wrap wrap-search" bis_skin_checked={1}>
                                <div className="search" bis_skin_checked={1}>
                                    <input
                                        type="text"
                                        className="searchTerm"
                                        placeholder="What are you looking for?"
                                        defaultValue=""
                                    />
                                    <button type="submit" className="searchButton">
                                        <svg
                                            className="svg-inline--fa fa-search fa-w-16"
                                            aria-hidden="true"
                                            data-prefix="fa"
                                            data-icon="search"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div> */}

                        {/* <div style={{ fontSize: "20px" }}>
                            <Link to='/account/sign-in'>
                                Login
                            </Link>
                        </div> */}
                        <div>
                            {token ? <div>
                                <Popover placement="bottomRight" content={content} visible={visible} onVisibleChange={handleVisibleChange}
                                    trigger={['hover', 'click']}>
                                    <Button type="text">
                                        <FaUserCircle size={24} />
                                    </Button>
                                </Popover>
                            </div> :
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Button: {
                                                /* here is your component tokens */
                                            },
                                        },
                                    }}
                                >
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        className='sgin-in-sign-out-dropdown-header header-drawer'
                                        placement="bottomLeft"
                                    >
                                        <Button className='header-login-register-on-hover'>sign in / sign up</Button>
                                    </Dropdown>
                                </ConfigProvider>
                            }


                        </div>
                        <div className='navbar-addTo-cart-icon'>
                            <Badge count={cartValue.length} showZero>
                                <IoBag style={{ fontSize: "26px" }} onClick={showDrawers} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                <Container>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="">
                            <Space>
                                <Button type="" onClick={showDrawer}>
                                    <RxHamburgerMenu />
                                </Button>
                                <Drawer
                                    title=""
                                    placement={placement}
                                    width={500}
                                    onClose={onClose}
                                    open={open}

                                >
                                    <h5>Products Category</h5>
                                    <Divider />
                                    <div className='d-flex navbar-product-category'>
                                        <div>
                                            Sherwani
                                        </div>
                                        <div>
                                            Jodhpuri
                                        </div>
                                        <div>
                                            Kurta sets
                                        </div>
                                        <div>
                                            shalwar kameez
                                        </div>
                                    </div>
                                    <Divider />
                                    <h5 className=''>Others</h5>
                                    <Divider />
                                    <div className='d-flex navbar-product-category'>
                                        <div>
                                            Contact us
                                        </div>
                                        <div>
                                            Terms & Conditions
                                        </div>
                                        <div>
                                            FAQ's
                                        </div>
                                        <div>
                                            Privacy Policy
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='navbar-social-media'>
                                        <div>
                                            <a href='https://www.facebook.com/' target='_blank'>

                                                <FaFacebookF />
                                            </a>

                                        </div>
                                        <div>
                                            <a href='https://www.instagram.com/' target='_blank'>

                                                <TiSocialInstagram />
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://x.com/?lang=en' target='_blank'>

                                                <FaTwitter />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://wa.me/45621856" target='_blank'>

                                                <FaWhatsapp />
                                            </a>

                                        </div>
                                    </div>
                                </Drawer>
                            </Space>
                        </Nav>
                        <Navbar.Brand as={Link} to="/">
                            <div className='nav-logo-div'>
                                <img src={logo} />
                            </div>
                        </Navbar.Brand>
                        <Nav>

                            <Nav.Link eventKey={2} href="#memes">
                                <FaUserCircle />

                            </Nav.Link>
                            <Nav.Link href="#deets">
                                Login
                            </Nav.Link>
                            <Nav.Link href="#deets">
                                <IoBag />

                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}

            <Drawer title="Shopping Cart" placement="right" onClose={onCloses} open={opens}>
                <div className="Product-addedCart-Side-Bar">
                    {/* {cartValue.map((elem, index) => (
            <div className="Product-addedCart-Side-details" key={index}>
              <div className="Product-addedCart-Image-con">
                <div className="Product-addedCart-Image">
                  <img src={elem.productImage} alt={elem.productName} />
                </div>
              </div>
              <div className="Product-details-addedCart-price-name">
                <h2>
                  {elem.productName} ({elem.Weight &&
                    elem.Weight > 999 &&
                    `${(elem.Weight / 1000).toFixed(1)} kg`
                  }
                  {elem.Weight &&
                    elem.Weight <= 999 &&
                    `${elem.Weight} g`
                  })
                </h2>
                <h3>
                  <span>Size : </span>
                  {elem.Product_Width}'H' X {elem.Product_Height}'W'
                </h3>
                <h3>
                  <span>Quantity : </span>
                  {elem.productQuantity}
                </h3>
                <h3>
                  <span>Price : </span>
                  {elem.product_price} ₹
                </h3>
              </div>
              <div className="Product-details-addedCart-cancel-btn">
                <div
                  className="Product-details-addedCart-cancel-btn-con"
                  onClick={() => removeCartData(index)}
                >
                  <RxCross2 />
                </div>
              </div>
            </div>
          ))} */}
                    {cartValue.length !== 0 ? (
                        <>
                            {
                                cartValue?.map((v, i) => (
                                    <div className="Product-addedCart-Side-details" >
                                        <div className="Product-addedCart-Image-con">
                                            <div className="Product-addedCart-Image">
                                                <img src={v.productImage} alt="" />
                                            </div>
                                        </div>
                                        <div className="Product-details-addedCart-price-name">
                                            <h2>
                                                {v.productName}
                                                {/* {elem.productName} ({elem.Weight &&
                    elem.Weight > 999 &&
                    `${(elem.Weight / 1000).toFixed(1)} kg`
                  }
                  {elem.Weight &&
                    elem.Weight <= 999 &&
                    `${elem.Weight} g`
                  }) */}
                                            </h2>
                                            <h3>
                                                <span>Size : </span>
                                                {v.productSize}
                                                {/* {elem.Product_Width}
                  'H' X {elem.Product_Height}'W' */}
                                            </h3>
                                            <h3>
                                                <span>Quantity : {v.productQuantity} </span>
                                                {/* {elem.productQuantity} */}
                                            </h3>
                                            <h3>
                                                <span>Price : </span>
                                                {/* {elem.product_price} */}
                                                ₹ {v.fixedPrice}
                                            </h3>
                                        </div>
                                        <div className="Product-details-addedCart-cancel-btn">
                                            <div
                                                className="Product-details-addedCart-cancel-btn-con"
                                                onClick={() => removeCartData()}

                                            >
                                                <RxCross2 />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <Divider />
                            <div className='shopping-cart-subtotal'>
                                <label>
                                    Subtotal
                                </label>
                                <label>
                                    {cartValue.reduce((acc, current) => {
                                        return acc + parseInt(current.product_price);
                                    }, 0)}
                                    {/* 2900/- ₹ */}
                                </label>
                            </div>
                            <div className="Product-details-Addedcart-btn-ViewCart">
                                <Link to='/checkOutPage'>
                                    <button onClick={onCloses}>
                                        CheckOut
                                    </button>
                                </Link>
                            </div>
                        </>
                    ) : <Empty description="Your cart is empty" />}
                </div>
            </Drawer>

        </>
    )
}

export default Header