import React, { useState } from 'react'
import { Divider } from 'antd';

import Accordion from "react-bootstrap/Accordion";
import { Button, Modal } from 'antd';

import "../Css/FilterSort.css";

const FilterSort = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortModalOpen, setSortModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sortShowModal = () => {
        setSortModalOpen(true);
    };
    const sortHandleOk = () => {
        setSortModalOpen(false);
    };
    const sortHandleCancel = () => {
        setSortModalOpen(false);
    };
    return (
        <>
            <section className='filter-sort-section'>
                <div className='container'>
                    <div className='filter-sort-main'>
                        <div className='filter-float'>
                            <label onClick={showModal}> FILTER </label>
                        </div>
                        <div className='filter-sort-divider'></div>
                        <div>
                            <label onClick={sortShowModal}> SORT </label>
                        </div>
                    </div>
                </div>
            </section>
            <Modal title="FILTER" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
                <Divider />
                <div className=''>
                    {/* <h5> FILTERS</h5> */}
                    <Accordion>

                        {/* {ServiceData.map((v,i)=>{
     return(
         <> */}
                        <Accordion.Item eventKey="1">
                            {/* <hr /> */}
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Price</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div>
                                    <div className='mb-1'>
                                        <span> ₹0 - ₹83,000 <label className='text-muted'>238</label> </span>
                                    </div>
                                    <div className='mb-1'>
                                        <span> ₹83,000 - ₹166,000 <label className='text-muted fs-8'>5</label> </span>
                                    </div>
                                    <div>
                                        <form className="form-inline">
                                            <div className='d-flex mb-2'>
                                                <div>
                                                    ₹
                                                </div>
                                                <div className="form-group input-field-from-to mb-2">

                                                    <label htmlFor="inputPassword2" className="sr-only">
                                                        Password
                                                    </label>

                                                    <input
                                                        type="From"
                                                        className="form-control"
                                                        id="inputPassword2"
                                                        placeholder="From"
                                                    />
                                                </div>
                                                <div>
                                                    -
                                                </div>
                                                <div className="form-group input-field-from-to mb-2">
                                                    <label htmlFor="inputPassword2" className="sr-only">
                                                        Password
                                                    </label>
                                                    <input
                                                        type="To"
                                                        className="form-control"
                                                        id="inputPassword2"
                                                        placeholder="To"
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn text-center filter-apply-btn mb-2">
                                                Apply
                                            </button>
                                        </form>

                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Sub Categories</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-sub-categories-list'>
                                    <span className=''>Men Kurta Set <label className='text-muted'> 31</label> </span>
                                    <span className=''>Kurta jacket Sets <label className='text-muted'> 31</label> </span>
                                    <span className=''>Sherwanis <label className='text-muted'> 2</label> </span>
                                    <span className=''>Jodhpuri <label className='text-muted'> 3</label> </span>
                                    <span className=''>Designer kurtas <label className='text-muted'> 31</label> </span>
                                    <span className=''>Indo western Men <label className='text-muted'> 1</label> </span>
                                    <span className=''>tuxedo <label className='text-muted'> 1</label> </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Color</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-color-list row pr-4 mt-2'>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1152}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1152"
                                                rel="nofollow"
                                                data-label="Brown"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1152}
                                                    option-label="Brown"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#702219"
                                                    data-option-type={1}
                                                    data-option-id={1152}
                                                    data-option-label="Brown"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#702219"
                                                    style={{ backgroundColor: "#702219" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Brown</span> <span className="count">1</span>{" "}
                                                    <span className="filter-count-label"> item </span>
                                                </div>{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1132}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1132"
                                                rel="nofollow"
                                                data-label="Red"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1132}
                                                    option-label="Red"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#be1616"
                                                    data-option-type={1}
                                                    data-option-id={1132}
                                                    data-option-label="Red"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#be1616"
                                                    style={{ backgroundColor: "#be1616" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Red</span> <span className="count">1</span>{" "}
                                                    <span className="filter-count-label"> item </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1144}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1144"
                                                rel="nofollow"
                                                data-label="Orange"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1144}
                                                    option-label="Orange"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#e87b23"
                                                    data-option-type={1}
                                                    data-option-id={1144}
                                                    data-option-label="Orange"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#e87b23"
                                                    style={{ backgroundColor: "#e87b23" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">
                                                        Orange
                                                    </span> <span className="count">1</span>{" "}
                                                    <span className="filter-count-label"> item </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1131}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1131"
                                                rel="nofollow"
                                                data-label="Yellow"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1131}
                                                    option-label="Yellow"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#ecc62c"
                                                    data-option-type={1}
                                                    data-option-id={1131}
                                                    data-option-label="Yellow"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#ecc62c"
                                                    style={{ backgroundColor: "#ecc62c" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">
                                                        Yellow
                                                    </span> <span className="count">7</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1150}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1150"
                                                rel="nofollow"
                                                data-label="Green"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1150}
                                                    option-label="Green"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#0d5b03"
                                                    data-option-type={1}
                                                    data-option-id={1150}
                                                    data-option-label="Green"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#0d5b03"
                                                    style={{ backgroundColor: "#0d5b03" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">
                                                        Green
                                                    </span> <span className="count">12</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1130}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1130"
                                                rel="nofollow"
                                                data-label="Blue"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1130}
                                                    option-label="Blue"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#092d74"
                                                    data-option-type={1}
                                                    data-option-id={1130}
                                                    data-option-label="Blue"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#092d74"
                                                    style={{ backgroundColor: "#092d74" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Blue</span> <span className="count">18</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1146}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1146"
                                                rel="nofollow"
                                                data-label="Beige"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1146}
                                                    option-label="Beige"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#d4b996"
                                                    data-option-type={1}
                                                    data-option-id={1146}
                                                    data-option-label="Beige"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#d4b996"
                                                    style={{ backgroundColor: "#d4b996" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Beige</span> <span className="count">5</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1143}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1143"
                                                rel="nofollow"
                                                data-label="Pink"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1143}
                                                    option-label="Pink"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#f12569"
                                                    data-option-type={1}
                                                    data-option-id={1143}
                                                    data-option-label="Pink"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#f12569"
                                                    style={{ backgroundColor: "#f12569" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Pink</span> <span className="count">7</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1129}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1129"
                                                rel="nofollow"
                                                data-label="Black"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1129}
                                                    option-label="Black"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#000000"
                                                    data-option-type={1}
                                                    data-option-id={1129}
                                                    data-option-label="Black"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#000000"
                                                    style={{ backgroundColor: "#000000" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">Black</span> <span className="count">6</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1133}
                                                type="radio"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_color=1133"
                                                rel="nofollow"
                                                data-label="White"
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                                    data-am-js="swatch-item"
                                                    option-type={1}
                                                    option-id={1133}
                                                    option-label="White"
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value="#ffffff"
                                                    data-option-type={1}
                                                    data-option-id={1133}
                                                    data-option-label="White"
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value="#ffffff"
                                                    style={{ backgroundColor: "#ffffff" }}
                                                    bis_skin_checked={1}
                                                />
                                                <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                                    {" "}
                                                    <span className="label">White</span> <span className="count">4</span>{" "}
                                                    <span className="filter-count-label"> items </span>
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Fabric</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-sub-categories-list'>
                                    <span className=''>ArtSilks <label className='text-muted'> 2</label> </span>
                                    <span className=''>Cotton Silk <label className='text-muted'> 3</label> </span>
                                    <span className=''>Dupion <label className='text-muted'> 1</label> </span>
                                    <span className=''>Linen <label className='text-muted'> 2</label> </span>
                                    <span className=''>Silk <label className='text-muted'> 31</label> </span>
                                    <span className=''>Velvet <label className='text-muted'> 1</label> </span>
                                    <span className=''>tuxedo <label className='text-muted'> 1</label> </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Occasion</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-sub-categories-list'>
                                    <span className=''>Wedding <label className='text-muted'> 2</label> </span>
                                    <span className=''>Festive <label className='text-muted'> 3</label> </span>
                                    <span className=''>Occasinal Collection <label className='text-muted'> 1</label> </span>
                                    <span className=''>Pooja <label className='text-muted'> 2</label> </span>
                                    <span className=''>Haldi <label className='text-muted'> 31</label> </span>
                                    <span className=''>Mehendi <label className='text-muted'> 1</label> </span>
                                    <span className=''>Engagements <label className='text-muted'> 1</label> </span>
                                    <span className=''>Sangeet <label className='text-muted'> 1</label> </span>
                                    <span className=''>Party <label className='text-muted'> 1</label> </span>
                                    <span className=''>Reception <label className='text-muted'> 1</label> </span>
                                    <span className=''>Casual <label className='text-muted'> 1</label> </span>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Work</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-sub-categories-list'>
                                    <span className=''>Embroidery <label className='text-muted'> 2</label> </span>
                                    <span className=''>Handwork <label className='text-muted'> 3</label> </span>
                                    <span className=''>Lunknowi <label className='text-muted'> 1</label> </span>
                                    <span className=''>Mirror <label className='text-muted'> 2</label> </span>
                                    <span className=''>Print <label className='text-muted'> 31</label> </span>
                                    <span className=''>Self work <label className='text-muted'> 1</label> </span>
                                    <span className=''>Sequins <label className='text-muted'> 1</label> </span>
                                    <span className=''>Stone <label className='text-muted'> 1</label> </span>
                                    <span className=''>Thread/Resham <label className='text-muted'> 1</label> </span>
                                    <span className=''>Reception <label className='text-muted'> 1</label> </span>
                                    <span className=''>Plain <label className='text-muted'> 1</label> </span>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <hr />
                            <Accordion.Header className="container">
                                {" "}
                                <h6 className=" accordion-title"> Select Size</h6>
                            </Accordion.Header>
                            <Accordion.Body className="container">
                                <div className='main-size-list row mt-2'>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5115}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5115"
                                                rel="nofollow"
                                                data-label={36}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5115}
                                                    option-label={36}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5115}
                                                    data-option-label={36}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    36
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5116}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5116"
                                                rel="nofollow"
                                                data-label={38}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5116}
                                                    option-label={38}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5116}
                                                    data-option-label={38}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    38
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5117}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                                                rel="nofollow"
                                                data-label={40}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5117}
                                                    option-label={40}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5117}
                                                    data-option-label={40}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    40
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5118}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                                                rel="nofollow"
                                                data-label={42}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5118}
                                                    option-label={42}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5118}
                                                    data-option-label={42}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    42
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5119}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                                                rel="nofollow"
                                                data-label={44}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5119}
                                                    option-label={44}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5119}
                                                    data-option-label={44}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    44
                                                </div>{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5120}
                                                type="checkbox"
                                            />{" "}
                                            <a
                                                href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                                                rel="nofollow"
                                                data-label={46}
                                                className="am-swatch-link inline-block"
                                            >
                                                <div
                                                    className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                                    data-am-js="swatch-item"
                                                    option-type={0}
                                                    option-id={5120}
                                                    option-label={46}
                                                    option-tooltip-thumb=""
                                                    option-tooltip-value=""
                                                    data-option-type={0}
                                                    data-option-id={5120}
                                                    data-option-label={46}
                                                    data-option-tooltip-thumb=""
                                                    data-option-tooltip-value=""
                                                    style={{}}
                                                    bis_skin_checked={1}
                                                >
                                                    {" "}
                                                    46
                                                </div>{" "}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* </>
     )
   })} */}

                        <hr />
                    </Accordion>
                    <div className="px-3 py-3 flex flex-col" bis_skin_checked={1}>
                        <div className="flex flex-col pb-8" bis_skin_checked={1}>
                            {" "}
                            <svg
                                className="mx-auto empty:bg-[#E2E8F0] empty:h-[50px]"
                                width={50}
                                height={51}
                                viewBox="0 0 50 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {" "}
                                <g clipPath="url(#clip0_2_12534)">
                                    {" "}
                                    <path
                                        d="M8.49 26.53L4.5 26.5V22"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                    <path
                                        d="M4.5 6V0.5H31.5C32.6046 0.5 33.5 1.39543 33.5 2.5V26.59L19.66 26.56"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                    <path
                                        d="M29.0239 12.4888L24.5994 11.6288C24.5994 11.6288 28.9325 4.15056 29 4L27.18 5.33924L18.1911 12.6123C17.9975 12.7784 18.0916 13.0803 18.3574 13.132L22.7819 13.992C22.7819 13.992 19.5667 19.9707 19.5462 20.1546L18.1911 22.5001L29.1895 13.012C29.3796 12.8417 29.2897 12.5405 29.0239 12.4888Z"
                                        fill="black"
                                    />{" "}
                                    <path d="M12 8.5H2" stroke="black" strokeMiterlimit={10} />{" "}
                                    <path d="M12 12.5H-5" stroke="black" strokeMiterlimit={10} />{" "}
                                    <path d="M12 16.5H3" stroke="black" strokeMiterlimit={10} />{" "}
                                    <path d="M38.21 26.5901H33.5" stroke="black" strokeMiterlimit={10} />{" "}
                                    <path
                                        d="M33.0901 6.33008H39.1146C39.7246 6.33008 40.3012 6.60842 40.6806 7.08601L48.2791 16.6506C48.7006 17.1812 48.9301 17.839 48.9301 18.5167V26.5901"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                    <path
                                        d="M36.5701 6.84009V15.7201C36.5701 16.8247 37.4655 17.7201 38.5701 17.7201H48.9301"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                    <path
                                        d="M42.29 30.5501C44.5488 30.5501 46.38 28.719 46.38 26.4601C46.38 24.2013 44.5488 22.3701 42.29 22.3701C40.0311 22.3701 38.2 24.2013 38.2 26.4601C38.2 28.719 40.0311 30.5501 42.29 30.5501Z"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                    <path
                                        d="M12.1901 30.5501C14.4489 30.5501 16.2801 28.719 16.2801 26.4601C16.2801 24.2013 14.4489 22.3701 12.1901 22.3701C9.93125 22.3701 8.1001 24.2013 8.1001 26.4601C8.1001 28.719 9.93125 30.5501 12.1901 30.5501Z"
                                        stroke="black"
                                        strokeMiterlimit={10}
                                    />{" "}
                                </g>{" "}
                                <defs>
                                    {" "}
                                    <clipPath id="clip0_2_12534">
                                        {" "}
                                        <rect
                                            width="54.43"
                                            height="31.05"
                                            fill="white"
                                            transform="translate(-5)"
                                        />{" "}
                                    </clipPath>{" "}
                                </defs>{" "}
                            </svg>
                            <p className=" text-center">Enjoy Free Shipping</p>{" "}
                            <a
                                className="w-fit mx-auto cmsb631-inline-block bg-black text-white py-2 px-2 hover:text-white text-center"
                                href="#"
                                onclick="plpBanner('Enjoy Free Shipping')"
                            >
                                KNOW MORE
                            </a>
                        </div>
                        <div className="flex flex-col" bis_skin_checked={1}>
                            {" "}
                            <svg
                                className="mx-auto empty:bg-[#E2E8F0] empty:h-[50px]"
                                width={51}
                                height={50}
                                viewBox="0 0 51 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {" "}
                                <path
                                    d="M6.5 25C6.5 14.5066 15.0066 6 25.5 6C35.9934 6 44.5 14.5066 44.5 25C44.5 35.4934 35.9934 44 25.5 44C21.4632 44 17.7204 42.7411 14.6429 40.5944"
                                    stroke="#0F0D1A"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />{" "}
                                <path d="M3.5 22L6.26923 25L9.5 22.5" stroke="black" />{" "}
                                <path
                                    d="M25.6765 34L17.2795 29.2084C16.7975 28.9334 16.5 28.4211 16.5 27.8662V21.2364M25.6765 34L33.7433 29.2127C34.2124 28.9343 34.5 28.4292 34.5 27.8837V20.9091M25.6765 34V26.1455M16.5 21.2364L25.6765 26.1455M16.5 21.2364L21.6618 18.2909M34.5 20.9091L26.4374 16.4234C25.965 16.1606 25.3897 16.1637 24.9201 16.4316L21.6618 18.2909M34.5 20.9091L30.0882 23.5273M25.6765 26.1455L30.0882 23.5273M21.6618 18.2909L30.0882 23.5273"
                                    stroke="#0F0D1A"
                                />{" "}
                            </svg>
                            <p className=" text-center">48 Hours Of Easy Returns</p>{" "}
                            <a
                                className="w-fit mx-auto cmsb631-inline-block bg-black text-white py-2 px-2 hover:text-white"
                                href="#"
                                onclick="plpBanner('48 HOURS OF EASY RETURNS')"
                            >
                                KNOW MORE
                            </a>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal title="SORT" open={sortModalOpen} onOk={sortHandleOk} onCancel={sortHandleCancel}>
                <Divider />
                <div className='sort-by'>
                    <label for="cars">Sort By:</label>
                    <select name="cars" id="cars">
                        <option value="volvo">Trending</option>
                        <option value="saab">Best Seller</option>
                        {/* <option value="opel">New</option> */}
                        <option value="audi">Beggest Saving</option>
                    </select>

                </div>
            </Modal>

        </>
    )
}

export default FilterSort