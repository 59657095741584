import React from "react";
import SignIn from "../Components/account/SignIn";
import SignUp from "../Components/account/SignUp";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
// import Navbar from "../Component/NavBar";
// import Footer from "../Component/Footer";

export default function Account() {
  const { page } = useParams();
  console.log(page);
  return (
    <>
      {/* {page === "sign-in" || page === "sign-up" ? null : <Header />} */}
      <div className="login-page">
        <div className="container">
          {/* <SignIn /> */}
          {/* <SignUp /> */}
          {page === "sign-in" ? <SignIn /> : null}
          {page === "sign-up" ? <SignUp /> : null}
        </div>
      </div>
      {/* {page === "sign-in" || page === "sign-up" ? null : <Footer />} */}
    </>
  );
}
