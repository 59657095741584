import React, { useEffect, useState } from "react";
import HomeSwiper from "./HomeSwiper";
import MainBanner from "./MainBanner";
import CircleComp from "./CircleComp";
import ReadyToShips from "./ReadyToShips";
import ShopCollection from "./ShopCollection";
import ExploreMore from "./ExploreMore";
import Footer from "./Footer";
import { get } from "../apiService";
import { Link } from "react-router-dom";
import ClothCard from "./ClothCard";
import HomeProduct from "./HomeProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CustomerReviewCard from "./CustomerReviewCard";
import reviews from "../Assert/reviews";

const Home = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCategory();
  }, []);
  const fetchCategory = async () => {
    try {
      const page = 1;
      const limit = 20;
      const response = await get(
        `/category.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&page=${page}&limit=${limit}`
      );
      if (response) {
        localStorage.setItem("category", JSON.stringify(response.list));
        setCategory(response.list);
      }
    } catch (error) {
      console.log("error fetch product category");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <MainBanner /> */}
      {/* <ReadyToShips /> */}
      <HomeSwiper />
      <ShopCollection />
      <CircleComp category={category} />
      <HomeProduct />
      <ExploreMore />
      <section className="Home-Section-7">
        <div className="container">
        <div className="home-our-categories-section-head">
            <h4 className="text-center text-under-line">What Our Customers Say</h4>
            <h3 className="fs-5">Top Reviews from Our Valued Shoppers</h3>
          </div>
          {/* <div className="Home-Section-3-head-con mb-4">
            <h2 className="fs-4">What Our Customers Say</h2>
            <h3 className="fs-5">Top Reviews from Our Valued Shoppers</h3>
          </div> */}
          <div className="Home-Section-7-testimonial">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              // autoplay={{
              //     delay: 2500,
              //     disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <CustomerReviewCard
                    description={review.reviews}
                    rate={review.stars}
                    name={review.user_name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
