import React from "react";
import "../Css/ExploreMore.css";

import imgg1 from "../Assert/SH15635-removebg-preview.png";
import img1 from "../Assert/explore(6).png";
import img2 from "../Assert/explore(2).png";
import img3 from "../Assert/explore(3).png";
import img4 from "../Assert/explore(4).png";

const ExploreMore = () => {
  return (
    <>
      <section className="mb-5">
        <div className="container">
          <div className="home-our-categories-section-head">
            <h4 className="text-center text-under-line">Explore More</h4>
          </div>
          <div className="row explore-now-col">
            <div className="col-lg-6 col-md-12">
              <div className="explose-more-cards explose-more-cards-one">
                <div className="explose-more-cards-details-con">
                  <h5>Sherwani </h5>
                  <p> Exquisite Wedding Sherwani</p>
                  <button>shop now </button>
                </div>
                <div className="explose-more-cards-image-con">
                  <img className="explose-more-cards-img" src={img1} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="explose-more-cards explose-more-cards-two">
                <div className="explose-more-cards-details-con">
                  <h5>Indo Western </h5>
                  <p> Contemporary Indian Fashion</p>
                  <button>shop now </button>
                </div>
                <div className="explose-more-cards-image-con">
                  <img className="explose-more-cards-img" src={img2} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="explose-more-cards explose-more-cards-three">
                <div className="explose-more-cards-details-con">
                  <h5> Sherwani Set </h5>
                  <p> Perfect for Weddings</p>
                  <button>shop now </button>
                </div>
                <div className="explose-more-cards-image-con">
                  <img className="explose-more-cards-img" src={img3} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="explose-more-cards explose-more-cards-four">
                <div className="explose-more-cards-details-con">
                  <h5>Rajputana Sherwani </h5>
                  <p> Royal Heritage Attire</p>
                  <button>shop now </button>
                </div>
                <div className="explose-more-cards-image-con">
                  <img className="explose-more-cards-img" src={img4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
            <div className='container'>
                <h4 className='my-4'>Explore More</h4>
                <div className='row explore-now-col'>
                    <div className='col-md-6'>
                        <div>
                                <img src='https://newcdn.kalkifashion.com/media/wysiwyg/01-kurta-jacket-pairs-mens-banner-580x280-20-02-24.jpg?w=580' loading='lazy' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                                <img src='https://newcdn.kalkifashion.com/media/wysiwyg/02-kurta-sets-mens-banner-580x280-20-02-24.jpg?w=580' loading='lazy' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                                <img src='https://newcdn.kalkifashion.com/media/wysiwyg/03-Swooning-Sherwanis-banner-580x280-20-02-24.jpg?w=580' loading='lazy' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                                <img src='https://newcdn.kalkifashion.com/media/wysiwyg/04-indo-fusion-edit-mens-banner-580x280-20-02-24.jpg?w=580' loading='lazy' />
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  );
};

export default ExploreMore;
