const reviews = [
  {
    user_name: 'Rahul Singh',
    reviews: 'Customer service was superb. My kurta arrived quickly and exceeded expectations. The fabric feels luxurious, and the design is fantastic. Shopping online for traditional wear has never been easier, thanks to Kataria.',
    stars: 5
  },
  {
    user_name: 'Vikas Reddy',
    reviews: 'Unique designs that truly stand out. I got a kurta for a festival, and it was stunning. The colors were vibrant, and the fit was perfect. I appreciate how quickly the order arrived. Kataria never disappoints.',
    stars: 5
  },
  {
    user_name: 'Raj Malhotra',
    reviews: 'Loved the variety in traditional outfits. I bought a kurta for Diwali, and it was the perfect choice. The style and design were on point. I will definitely return to Kataria for more festive clothing options.',
    stars: 5
  },
  {
    user_name: 'Amit Sharma',
    reviews: 'Great quality and design. The outfits are well-made and comfortable. I purchased a sherwani and was happy with the fit and fabric. Kataria has an excellent collection of traditional wear. Highly recommended for weddings and special occasions.',
    stars: 5
  },
  {
    user_name: 'Sandeep Verma',
    reviews: 'I ordered a kurta for my cousin\'s wedding, and it was absolutely perfect. The intricate detailing and quality of the fabric stood out. Kataria made sure it was delivered on time. Highly satisfied!',
    stars: 4
  },
  {
    user_name: 'Karan Patel',
    reviews: 'Bought a sherwani for my engagement, and I couldn’t have asked for more. The design was exactly as shown on the website, and it fit me perfectly. Kataria has become my go-to for traditional wear.',
    stars: 5
  },
  {
    user_name: 'Mohit Gupta',
    reviews: 'The range of kurtas at Kataria is impressive. I purchased one for a family event, and the quality was beyond my expectations. Comfortable and stylish – I’ll definitely be shopping here again.',
    stars: 4
  },
  {
    user_name: 'Aditya Rao',
    reviews: 'I got a sherwani for a wedding, and it was a showstopper. The fabric felt premium, and the craftsmanship was outstanding. The compliments just kept coming. Kataria really knows how to deliver traditional wear!',
    stars: 5
  },
];

export default reviews;
