import React, { useLayoutEffect } from 'react';
import "../Css/AboutUs.css";
// import HappyCustomers from "../Images/icons/like-button.png";
// import FoundingYear from "../Images/icons/people.png";
// import ProductOrders from "../Images/icons/delivery-van.png";
// import QualityProducts from '../Images/icons/medal.png';
// import About from "../Images/about-01.png";
// import ProductData from '../Component/ProductData';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Footer from '../Components/Footer';
import Milestone from '../Components/Milestone';
// import Testimonial from '../Component/Testimonial';
// import demotestimonial from "../Images/demo-removebg-preview.png";
import aboutusKataria from "../Assert/aboutKatariaNew.jpeg";
import aboutUsGallery1 from "../Assert/aboutUsGallery(7).jpg";
import aboutUsGallery2 from "../Assert/aboutUsGallery(2).jpg";
import aboutUsGallery3 from "../Assert/aboutUsGallery(3).jpg";
import aboutUsGallery4 from "../Assert/aboutUsGallery(4).jpg";
import aboutUsGallery5 from "../Assert/aboutUsGallery(5).jpg";
import aboutUsGallery6 from "../Assert/aboutUsGallery(6).jpg";
import { Image } from 'antd';
import CustomerReviewCard from '../Components/CustomerReviewCard';

const AboutPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const reviews = [

        {
            user_name: 'Priya Mehta',
            reviews: 'Loved the fabric and craftsmanship. I ordered a lehenga for a family event, and it was exactly what I was looking for. The intricate embroidery was beautiful. Kataria offers great ethnic wear with a smooth shopping experience.',
            stars: 4
        },
        {
            user_name: 'Rahul Singh',
            reviews: 'Customer service was superb. My kurta arrived quickly and exceeded expectations. The fabric feels luxurious, and the design is fantastic. Shopping online for traditional wear has never been easier, thanks to Kataria.',
            stars: 5
        },
        {
            user_name: 'Sneha Kapoor',
            reviews: 'Beautiful traditional wear. I ordered a saree, and it was elegant and stylish, just as described. The delivery was fast, and the packaging was top-notch. I will definitely shop from Kataria again in the future.',
            stars: 4
        },
        {
            user_name: 'Vikas Reddy',
            reviews: 'Unique designs that truly stand out. I got a kurta for a festival, and it was stunning. The colors were vibrant, and the fit was perfect. I appreciate how quickly the order arrived. Kataria never disappoints.',
            stars: 5
        },
        {
            user_name: 'Pooja Iyer',
            reviews: 'Good value for money. I bought a salwar suit, and it exceeded my expectations. The quality was impressive, and the colors were as shown on the website. Kataria offers a great combination of price and quality.',
            stars: 4
        },
        {
            user_name: 'Raj Malhotra',
            reviews: 'Loved the variety in traditional outfits. I bought a kurta for Diwali, and it was the perfect choice. The style and design were on point. I will definitely return to Kataria for more festive clothing options.',
            stars: 5
        },
        {
            user_name: 'Neha Desai',
            reviews: 'The vibrant colors made my saree perfect for a wedding. The fabric was light and comfortable for a long day. Kataria offers a wide selection of ethnic wear, and the shopping experience was smooth and efficient.',
            stars: 5
        },
        {
            user_name: 'Amit Sharma',
            reviews: 'Great quality and design. The outfits are well-made and comfortable. I purchased a sherwani and was happy with the fit and fabric. Kataria has an excellent collection of traditional wear. Highly recommended for weddings and special occasions.',
            stars: 5
        },
    ];

    return (
        <>

            {/* ---------------- Section 1 ---------------------- */}
            <section className='about-us-new-Section-1'>
                <div className='container'>

                    <div className='Contact-Section-con'>
                        <div className='about-us-new-Section-Text'>
                            <h2>
                                About Us
                            </h2>
                            <div className='about-us-new-Section-NavPage'>
                                <span className='d-flex'><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ///////////////// Section 1 ///////////////////// */}

            <section className='AboutUs-Section-1'>
                <div className='container'>
                    <div className='AboutUs-Section-1-con'>
                        <div className='row'>
                            <div className='col-md-4 '>
                                <div className='About-Section-1-Image-con'>
                                    <div className='About-Section-1-Image'>
                                        <img src={aboutusKataria}
                                        // {About}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='About-Section-1-Details'>
                                    <div className='About-Section-1-Details-con'>
                                        <h2 className='Smalltitle'>About Us</h2>
                                        <h3 className='Headtitle'>Online shopping includes both buying things online.</h3>
                                        {/* <h4>Shop Easily, Live Stylishly with Eco-Conscious, Artisan-Crafted Products.</h4> */}
                                        {/* <p>At Kataria's, we redefine online shopping by combining convenience with quality. Our easy-to-navigate platform ensures a seamless and enjoyable experience, allowing you to purchase our uniquely crafted bags, tables, and chairs from the comfort of your home. </p>
                                        <p>We're committed to bringing you eco-friendly, stylish products that don't just fill spaces but enrich lives. Explore our diverse range and find everything you need to beautify your surroundings with just a few clicks. With Kataria's, discover the true potential of online shopping – simple, secure, and satisfying.</p> */}
                                        <p className='text-center'>
                                            Welcome to Katarias, your one-stop destination for all your fashion needs! At Katarias, we offer a diverse and stylish collection of suits, sherwanis, suiting, and shirting options that cater to every occasion and style preference. Our store is a treasure trove of high-quality garments crafted with precision and attention to detail. Whether you're searching for a classic suit for a formal event or a majestic sherwani for a cultural celebration, Katarias has something for everyone.
                                        </p>
                                        <p className='text-center'>
                                            Visit us in Hubballi to experience personalized service, top-notch quality, and a shopping experience that will elevate your wardrobe game. Let Katarias be your fashion partner in creating memorable and fashionable looks for every moment in your life! Now to serve you all immensely we are back with our online website from which you can order your apparel from any part of the world.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* milestone start */}
            {/* <Milestone /> */}
            {/* milestone end */}
            {/* Gallery start*/}
            <section>
                <div className='container'>
                    <h2 className='Smalltitle mb-4'>A Glimpse into Our Store</h2>
                    <div className='row'>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100 h-100'
                                // width=
                                src={aboutUsGallery1}
                            />
                        </div>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100'
                                // width=
                                src={aboutUsGallery2}
                            />
                        </div>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100'
                                // width=
                                src={aboutUsGallery3}
                            />
                        </div>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100'
                                // width=
                                src={aboutUsGallery4}
                            />
                        </div>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100'
                                // width=
                                src={aboutUsGallery5}
                            />
                        </div>
                        <div className='col-md-4 mb-2'>
                            <Image
                                className='w-100'
                                // width=
                                src={aboutUsGallery6}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* Gallery end*/}

            {/* ///////////////////// Section 2 ///////////////////// */}

            <section className='AboutUs-Section-2'>
                <div className='container'>
                    <div className='AboutUs-Section-Counter'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src="https://jutiepie.in/static/media/like-button.e0418aee5cf9efaa47ea.png"
                                            // {HappyCustomers} 

                                            />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>750 <span>+</span></h2>
                                        <h3>Happy Customers</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src="https://jutiepie.in/static/media/people.9b509fbdd4993e39818d.png"
                                            // {FoundingYear}
                                            />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>2016</h2>
                                        <h3>Founding Year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src="https://jutiepie.in/static/media/delivery-van.b36993ea8115f5c5201f.png"
                                            // {ProductOrders} 

                                            />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>120 <span>+</span></h2>
                                        <h3>Product Orders</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src="https://jutiepie.in/static/media/medal.b1a4472e2aa07ccef4ae.png"
                                            // {QualityProducts} 

                                            />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>60 <span>+</span></h2>
                                        <h3>Quality Products</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* //////////////////////////////// Section 7 ////////////////////// */}

            <section className='Home-Section-7'>
                <div className='container'>
                    <div className='Home-Section-3-head-con mb-4'>
                        <h2 className='Smalltitle'>What Our Customers Say</h2>
                        <h3 className='Headtitle'>Top Reviews from Our Valued Shoppers</h3>

                    </div>
                    <div className='Home-Section-7-testimonial'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            // }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {reviews.map((review, index) => (
                                <SwiperSlide key={index}>
                                    <CustomerReviewCard
                                        description={review.reviews}
                                        rate={review.stars}
                                        name={review.user_name}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AboutPage