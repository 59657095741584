import React, { useEffect, useState } from 'react'
import { FcCancel } from "react-icons/fc";
import { useCartContext } from '../context/addToCart';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { get } from '../apiService';
const FailedPage = () => {

    const { cartValue, setCartCount, amountDetails, setCartValue } = useCartContext();

    const { id } = useParams();

    const removeCartData = (index) => {
        const prevData = cartValue;

        prevData.splice(index, 1);
        // setCartValue([...prevData]);

        // addtoLocatStorage(prevData);

        setCartCount(prevData.length);
    };

    // =================== add to local Storage ==================

    // const addtoLocatStorage = (data) => {
    //     localStorage.setItem("cart", JSON.stringify(data));
    // };


    const [orderProduct, setOrderProduct] = useState(null);

    const fetchData = async () => {
        try {
            const response = await get(`/orders.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&order_id=${id} &page=1&limit=1`);
            setOrderProduct(response.list[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
        setCartCount(0);
        setCartValue([]);
    }, [id]);
    // 
    console.log("failed page   "+  id);
    return (
        <>
            <section className='SuccessPage-Section-con'>
                <div className='container shadow'>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <FcCancel />
                                    <h2>Order Failed</h2>
                                    <p>We regret to inform you that there has been an issue with your order at Kataria's, and it could not be successfully confirmed. We sincerely apologize for any inconvenience this may have caused. Our team is actively working to address the issue and will provide further assistance shortly.</p>
                                </div>
                            </div>
                            <div className='Order-Success-Details-con-Main'>
                                <div className='Order-Success-Details-con-Main-head'>
                                    <div className='Order-Success-List-head'>
                                        <h3>Order Id : - <span>{orderProduct && orderProduct.order_id}</span></h3>
                                    </div>
                                    <div className='Order-Success-List-head'>
                                        <h3>Ordered on : - <span>{orderProduct && moment(orderProduct.created_at).format("llll")}</span></h3>
                                    </div>
                                </div>
                            </div>


                            <div className='Order-Success-List-con'>
                                <div class="Order-Success-List">
                                    <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                        {
                                            orderProduct && orderProduct?.products?.map((elem, index) => {
                                                console.log("first", elem)
                                                const { size, image, color } = elem
                                                return (
                                                    <>
                                                        <div className='Product-addedCart-Side-details'>
                                                            <div className='Product-addedCart-Image-con'>
                                                                <div className='Product-addedCart-Image'>
                                                                    <img src={elem.image} />
                                                                </div>
                                                            </div>
                                                            <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                <div className='Product-details-addedCart-price'>
                                                                    <h2>{elem.product_name}</h2>
                                                                    <h3><span>Color : </span>{elem.color_name}</h3>
                                                                    <h3><span>Size : </span>{elem.product_size} </h3>
                                                                </div>
                                                                {/* <h4>{elem.product_price}/- Rs</h4> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='Product-details-Addedcart-total-con'>
                                        <div className='Product-details-Addedcart-total'>
                                            <div className='Product-details-Addedcart-total-title'>
                                                <h4>Total:</h4>
                                            </div>
                                            <div className='Product-details-Addedcart-total-Amount'>
                                                <h5>{orderProduct && orderProduct.amount} /- Rs</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order-success-page-btn-con'>
                                        <Link to="/">
                                            <button>
                                                Back to home
                                            </button>
                                        </Link>
                                        <Link to="/product/all">
                                            <button>
                                                Continu Shopping
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default FailedPage