import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import required modules
import { Autoplay } from 'swiper/modules';
import "swiper/css";

const OfferSwiper = () => {
    return (
        <>
            <section className='bg-black text-center text-white text-uppercase py-1 fs-6'>
                <div>
                    <Swiper className="mySwiper" 
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}>
                        <SwiperSlide>
                            <div>
                                Flat 40% off on Selected Collection
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                Flat 20% off on Selected Collection
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                Flat 10% off on Selected Collection
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default OfferSwiper