import { Rate } from 'antd'
import React from 'react'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { FaUserCircle } from 'react-icons/fa'
import "../Css/CustomerReviewCard.css";
import googleReview from "../Assert/googleReview.png";
// import googleReview form '../../'

const CustomerReviewCard = ({description,rate,name}) => {
    return (
        <>
            <div>
                <div className=' gap-2 customer-review-card-start'>
                    <div className='customer-review-card pb-2'>
                        <div className='user-googleReview-div'>
                            <FaUserCircle className='fs-4' />
                            <img className='user-googleReview-img' src={googleReview} />
                        </div>
                        <h6 className='ml-1'> {name} </h6><label> <BsFillPatchCheckFill className='customer-verified' /></label>
                    </div>
                    <div>
                        <Rate disabled defaultValue={rate} />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
        </>
    )
}

export default CustomerReviewCard