import React, { useState } from 'react'
import "../Css/ClothCard.css";

const ClothCard = ({pPrice,pImage,pName ,pImagesSecond}) => {
  const [backgroundImage, setBackgroundImage] = useState(`url(${pImage})`);

  const handleMouseEnter = () => {
    setBackgroundImage(`url(${pImagesSecond})`);
  };

  const handleMouseLeave = () => {
    setBackgroundImage(`url(${pImage})`);
  };
  return (
    <>
      <div>
        <div
          className="main-card-product reletive"
          style={{ backgroundImage, height: "400px", width: "100%",backgroundSize:"100% 100%", borderRadius:"15px",boxShadow:"0 0 5px gray",margin:"10px 0px" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
    
          <a className='card-product-shop-now'>
            Shop Now
          </a>
        </div>


        <div className='clothCard-product-deatils-main-con'>
          <span className='clothCard-product-title my-1'>
            {pName}
          </span>
          <div className='clothCard-product-deatils-main-con-price'>
            <label className='text-muted pr-1'>MRP</label> <label className='product-price'>: ₹{pPrice}</label>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClothCard