import React from 'react'
import '../Css/Footer.css'

import securePayment from "../Assert/secure-payment-India-new-1.png";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <footer className='page-footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2'>
                            <h6 className='mb-4'> Designer Wear</h6>
                            <div className='footer-list'>
                                <label>Shooting </label>
                                <label>Shirting</label>
                                <label> Ready Mades</label>

                            </div>
                        </div>
                        <div className='col-md-2'>
                            <h6 className='mb-4'> About us</h6>
                            <div className='footer-list'>
                                <Link to='/About-us'>
                                    <label>About Us </label>
                                </Link>
                                <Link to='/contact-page'>
                                    <label>Contact Us</label>
                                </Link>
                                {/* <Link to='/About-us'>
                                    <label> Testimonial</label>
                                </Link> */}
                            </div>
                        </div>

                        <div className='col-md-2'>
                            <h6 className='mb-4'> POLICIES</h6>
                            <div className='footer-list'>
                                <Link to='/PrivacyPolicie'> <label>Privacy Policies </label> </Link>
                                <Link to='/PaymentPolicy'> <label>Payment Policy </label> </Link>
                                <Link to='/TermsofService'> <label>Terms & Conditions </label> </Link>
                                <Link to='/ShippingPolicy'> <label>Shipping Policy </label> </Link>
                                <Link to='/RefundPolicy'> <label> Returns</label> </Link>
                                {/* <Link to='/faqs'>  <label>FAQ's</label></Link> */}

                            </div>
                        </div>
                        <div className='col-md-2'>
                            <h6 className='mb-4'> MY ACCOUNT</h6>
                            <div className='footer-list'>
                                <label>Shopping Bag </label>
                                <label>Wishlist</label>
                                <label> Order History</label>
                                <label>Order Tracking </label>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <h6 className='mb-4'> SAFE & SECURE PAYMENT</h6>
                            <div>
                                <img src={securePayment} />
                            </div>
                            <h6 className='my-4'> Follow us on</h6>
                            <div className='social-media-icon'>
                                <label>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <FaInstagram />
                                    </a>
                                </label>
                                <label>
                                    <a href='https://www.facebook.com/' target='_blank' >

                                        <FaFacebookF />
                                    </a>
                                </label>
                                <label>
                                    <a href='https://wa.me/1234567895' target='_blank'>
                                        <FaWhatsapp />
                                    </a>
                                </label>
                                <label> </label>
                            </div>
                        </div>
                    </div>
                    <div className='main-return-policy-div row my-5'>
                        <div className='col-md-3 col-sm-6'>
                            <div className='inner-return-policy-div'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEEklEQVR4nO2ay3MVRRTGf9F4b8rwugHNwo2PlcSCuNWt8pASAxtFt1pmI6FYyQJ1J0bcxML/IzyLDZgSMKKJYSMJQV1oeKo7E26FqlBHvrFOJTNzZ+bOzL2V4qvqqpvp092nZ7pPf+frwCOsXqwD3gS+AEaBq8BtYF7ltp6NysZs19ImqALvAGeARWApZbE2p4G3gUorJtAFDAF/OqfuAeeAw8AA8CJQA55QqemZ1X0CnFeboP0fwH71XQq2A7POgR+B94H1GfraAHwA/OT6uwZso0DYmzrmBpzIecAdwM+u/6+1dHNFr968DfAv8BHweN6D8LDPIQUHG+sH4Om8On/WLSWLOi9RPLYAM26pmQ9N4SnX4WVgE+WhBlzQ2Ne1KjLviWA5XQK6KR/dwLhbZpn2zDduOfXQOmx0q8ICQCrsdBu7jD2RZM/MyycL/4mX1HU1sujULjggn2aTHpoH3TlRRIjNik5gSr7ZpGJRdbTjddoPb8i3G42+ynuOdrQjOrRSzMd34wzPysi4U7viQ/lojDvyALovRpqEAFaUV9hnngOOpKTiWdvXgLpSACOdKzCgmRoVT4IjIfmFPaOE9t/KfndY5VFVWj6RBHMhjtgzSmj/mey/DKs8HTfLhI5YxKOE9ntkfyKs8poqLYvLujQ+Tz6Pptr3yd6oywr8pUrjNklQkTNzTWz2rO03yde7YZV1VbZEBEiJqtMJVu9E/lZlKyl7moTPfL2Tx2ZvJfrk63RY5SlVvkX7Y698PR53IJp4FoWLSjlNHi0K60RaLV9vdCAOx1EUUwCjMOEIW6WgTXzW5UNRGJON6cexpDGUjAEvKHYHn/VJ8oP1ddJt4ucj7HocaYxcGcHbMBkzCi+7CGfL7Jnm5/BfHyY3Lelg3hpjOyg7o1QNEyvTYuOwGfhdtjapfdn8/3/Mf9TXbw2ipiVWk7KNHbPLkblGakWv9krAkywQvJZiApZKf+/aW9RsJI/ucsp9Nan4MJlAfOhQxhbsm0AVPKoXsVmXOWv124Tqr4Bfnf0dZaTWVyPx4Yra2PVDKjnIBOUkMEcPAbdSXPLcBD4G1iQc46BjvNW0At28xLGkeAx4VfH9O03snsotPbP09hXZJkU/sCCfUl9lHHNvICm1L4pXzcqXkWZF7PEWidhrFOKbErGDtzHtrhXs77LQo0jY9LVCgOfcp51pcFjlhX43Zi4XPWFXb/PSXi0c5o1ORaeFIq7eoi5DpxTd8kCHDrsrrv+RIi5DPXYsu56e0KFopDPLPhh0tGOpjOvp5V/nwLJ/GKiLXn8q3alPYbuislGXRntlM+Z0goB27C/6K0ShKlX8jFKApZRlURxrX6smEIb1SnSGpQBeFSWvq9jvX5THDMu2yEzzEWglHgDRqIi28r/YIwAAAABJRU5ErkJggg==" />
                                <div>
                                    24x7 <br />
                                    Customer Support
                                </div>

                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='inner-return-policy-div'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVR4nO2VOw7CQAxEp6KCY1EQuA+fE5Jwm3RpGBTJShchyCa2V/Mkd46V0VvvAkKsCpPXRHVBskEFCQZlJBiUkWCweiNMWhPVBMl2xPjtPxVkYygjM5wBdACGBYs62IwGTkYuK9w+jUeQl/VeAezwP+O3N5vVegTprXeP5RxsVu8RpLPeewEjD08jRwDvwjty8ggCW862wK31/CHEiN6RaFAvezAoI8GgjASDMhIMykgwKCPBoIwEgzISDMpIViNMUrNUE0TAmQ+l9gyTyGhcAQAAAABJRU5ErkJggg==" />                                <div>
                                    Free Shipping*
                                </div>

                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='inner-return-policy-div'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADDElEQVR4nO2aS09TQRiGH2OhTbxguYhL2Wk0yq9QqhhBV162RlaI/QHi0qArIn9EbQiJ0SAaUS5KYhSorFQWRl0WqsSaSd6TTIBiT8+lZxLepOlpZ+bMvGe+mfm+9zuwCzfwBnjts81B4DJwD3gCLAHfgXWgDPwCPgEF4D5wEcgSMSr6/A9NwBXgKfDbalfrZwOYAK4D6UYQaQbywKpV9w/wHBjWzBwDOjTAJj3940AfcEd1163233TPTFxEzgNFq84CcKNOMzmktrPW/T4DOSIkYp7UQ+Cvyj6E2SHQA7yz+h4LY3Y2EzkMzOg/Yw63gRThYy9wCyipL9NnZ1hEjgLL+m1MqpvocUq7ntdnV1Ai7dYNZzUzcSELvFTfK8CRIESm9f0WOED82GeNYaaeNWPv9StB7TQg2iyrMBuAL/g51KaIZ82U1J/Z3SIh8oJ4MGQt/lAPzbiRAt6LjCHlNM6JyKrrs7IHmBOZqziOmyIyjuPIKr4x3nYLjmNSs3IBx3FXREZwHP0i8gjHcUJEFnEc7SJixI0tmIrJbwoDaSvI24KyCo2w4DSRnypsJfno2Mm0vHDWSDZOL/aCCo0CmHRc2mn7faBCI545fSD2qfAZ7rgovdWcsQ3tBEYBTCpaLafRCOfbYkJMjYyZVAxojGZNV8U1S8NKamA1rzGaLMCOB81XVTxL8tCrsX2pJQ2RV+V5abFJEh8WNLbBWhpkJO1XJCgnBXmNaclPUiinRiWJY41GN7CmMZ3x23jMegJGtmykX1XUWEbruUHGyolMS1COG/uVmK3oOx3kaSxairz5HefB98pKxwUW0rusqTVmdpp41kRRfS4r2RQKOi0zK0l7jSL1ltLutGaZU+jJpYy1AVQkKOdCPLFNttg7J7yFHUnu3UPPpvT0nGTMbJ3rYMByOyoyJd9bbJDZGbLcmYo80km9MNCvKK5NGkCzrk8qKBpWXU8n8NyOwahnoRrSUsXHFQL4SRB5b0sU5AA2hMB2aJEWa6K2x3qB5oeeelnXHxWejqhu1XhiFziOf7QdImdWDCRDAAAAAElFTkSuQmCC" />                                <div>
                                    Easy Returns
                                </div>

                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='inner-return-policy-div'>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFP0lEQVR4nNWaeYxfUxTHP1OmNU2TtpTaWvvaIEiVaE2I2Jc0jITYGtEQaitaRi0hWmtsDbVGpZYKKaUqhOrwR9NBiGaIoAvtoMbeqZrOk5N8b3J/v3l+777l95uZk9zM7915973zvefce77nvAvwHvAXsAJ4C3gcuAk4D2gEdgPq6QcSBbYOoBWYDzwITAOagEOBHYG6vgLkQOAU4FKv7yPge6ArAOifwBfAIuAxoFlWnQDsWgurRmqV+rb0+s6X683x+jYEAO3SpHwIPA/cBVymybNJHFYLICF9I4CDvb47geeAD1K47x+y6hvAo8ANwLnAeGC0JjQ3kE3APzkBj5ZS53h9b0r53wOt2gbcA+yVFciFar6sV8sDLq7vNOByuZ/r+wHYXAbqPmBg2pfEyd5qtbAm2jAOL7PSS27HjBvcAiwlm4RacxWwMmBsVKHvV/09+/9uzCOhz9taLWlsp1rcfZfo7/tpXhwqLytoZpEoRpeT1XxZoTZS96/3B9ckaCVIt1qS1KvtI91/cQvR3wlWa41YDLgDuBg4HtgX2KrKQB4BHk5xf5P0XmcXV0rx1YFUpB1YJhe6F5gCnKroPJTayqs+EF/q5WKNoiIzgCeBd4CvtOiSgP4GfAa8DjwETNXMHSafLkqO8+JKDyAhsgMwDjgLuE6usBD4XCCSgHYqOi9WytDs0ZBRwBYV3j1EbPv2Mn6XCUiSGPk7SO42Re43X+7YHgB0E/AdsERMulVuH+cNN1YTyFDtJjbDE5UW3CzLOUAhLhrFNLPC19719mmAGJfZScz2RK2dqSJuz4r0fQysATZmUO5nuZoxCdc3QxMw0eszt3KSCGSElLTF/aVHAbI0yzsWAE94fU3aSMZkoCOEADHSdbWyvLjFuca7fhqYqftdn+1IuwCDC1IwiaI4LtgDyAPeQ5YkmLOaCkaBFMVJCZCTdGH+fUaVlI5j03EKRjFjK0kJkMW6mJbDxHmUKQxIuy5G5jBxHmXy5EH7611tadwjVOIsVy25Rnou8JUeVhCQUMvllVHA2rgM0UhYEaluNWWIAvO1wI/S28pNA3wgr/SCYgNVbjVudoIqkxafZgHPqL61TPl9XBHQuNhw9zD/HxcUoNw2wH7AUcCZqibeCszWZC3V4uzIwBY2aOxceVBJvTny4ki3MkOjEQ36/2BF7XEqb04CpgP36963lX+sLcs2Q1qXtk5LAd4F5ik4G7W/SPWtI4A9yoJzrLiHNir39V/0d4ZZ69CstSiDM0vcpoKb41q2bW5bgPVLxN+htlPNNq3yZolPlE02iyEfreKds2zVJaoQRywbHFtGp+dptlelAPoT8KnS39kqTrsPSXsWVdSICuBVxn5P18L2afxKlUpDwNp2annNa0rCpisFtu8ruwODigJSRP02rjj9bQqw64DliuRW2Lhelf2d+0o1vk5uHKldoVz/xbJ1WAlg8It7uxpfJ7CHeH0z/TH9uRpf8tvFjvLKeFapZTW+5HeLfljE7m/V+JIxk7wFYxypP1XjS4AMUG3KURLjOkfWMiLnqMb3sGKD6rDdMUFquTiTAbxKBYqxBRekC1+TB0jhtsBPDJ2q0ltB7yngFu1Ox4h6DOotIOUyXNXvJn1HmaWF3JqiCunOsCzUaQl3hmW8qEddjXbJitIgZY4FJiuBmiMLfQP8GwB0o+61MXM1WZP1zDHKh6oOJEnch6MJYrvNOnCzSKcd4kq0UVnbrMMCdsDnBeBu8TX3lawmQIqwalegC/d5Mava4TfLXcyqdjLJdlg7JGdB0Q7NtfwHk+ZrfULUTNsAAAAASUVORK5CYII=" />
                                <div>
                                    Custom
                                    Fitting
                                </div>

                            </div>
                        </div>

                    </div>
                    <div>
                        <p className='ship-all-over-india'>
                            We ship all over India
                        </p>
                    </div>
                    <div className='all-rights-reserved'>
                        <p className='text-muted'>
                            Copyright © 2024 Kataria's All Rights Reserved.
                        </p>
                        <p className='text-muted footer-design-by-skdm'>
                            <a className='text-muted' href='https://skdm.in/' target='_blank'>Designed by Shree Krishna Digital Marketing</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer