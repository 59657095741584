import React, { useEffect, useLayoutEffect, useState } from 'react'
import Accordion from "react-bootstrap/Accordion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCartContext } from '../context/addToCart';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../Css/ProductDetailsPages.css'
import Breadcrumbs from '../Components/Breadcrumbs'
import { ConfigProvider, Space } from 'antd';
import Footer from '../Components/Footer';
import RecommendationCard from '../Components/RecommendationCard';
import CustomersReviews from '../Components/CustomersReviews';
import { Link, useParams } from 'react-router-dom';
import ProductData from "../Assert/ProductData";
import axios from 'axios';
import { get } from '../apiService';


const ProductDetailsPages = () => {
    const { slug } = useParams()
    // const [productDetail, setProductDetail] = useState({})
    // const [selectedColorData, set]
    const [loading, setLoading] = useState(false)
    const [getData, setGetData] = useState(null)

    const [productAlldetail, setProductAllDetail] = useState({})
    const [price, setPrice] = useState(null);
    const [allSizeData, setAllSizeData] = useState([])
    const [allImage, setAllImages] = useState([])
    const [recommendedProduct, setRecommendedProduct] = useState([])
    const [addToCart, setAddToCart] = useState({})
    const [mainImageSrc, setMainImageSrc] = useState(null);
    const [slectedColor, setSelectedColor] = useState(null)
    const [slectedSize, setSelectedSize] = useState(null)
    const [getProductDetail, setGetProductDetail] = useState([])
    const [productID, setProductID] = useState(null)
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        fetchproductDetail()
    }, [])
    const handleSelectedSize = (size) => {
        setSelectedSize(size)
    }
    const handleSelectedColor = (color) => {
        setSelectedColor(color)
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    const handleSelectedColorData = (color) => {
        console.log('color' + color);
        // if(slug === )
        setAllImages(color?.image)
        setMainImageSrc(color?.image?.[0]?.images);

        // Set the price based on the selected color
        setPrice(color?.product_price);
        setAllSizeData(color?.size)
        // Set the selected color name
        setSelectedColor(color?.color_name);

        // Set the selected size to the first size option of the selected color
        setSelectedSize(color?.size?.[0]);

    }

    const handleThumbnailClick = (src) => {
        setMainImageSrc(src);
    };

    const { handleAddCart, cartValue } = useCartContext()
    // console.log(cartValue);
    const handleaddToCart = (pdetailTocart) => {
        // alert('qwer')
        // console.log("pdetailTocart", pdetailTocart);
        // const addToCartData = {
        //     pSize:

        // }
        // (pdetailTocart.pColor.)
        console.log("product detail json" + JSON.stringify(pdetailTocart))
        const data = {
            productid: pdetailTocart.product_Id,
            productName: pdetailTocart.product_name,
            colorName: slectedColor,
            product_price: price,
            fixedPrice: price,
            productImage: mainImageSrc,
            productColor: slectedColor,
            productQuantity: 1,
            productSize: slectedSize.product_size,
            product_type: pdetailTocart.product_type,
            sizeid: slectedSize.size_id,
            colorid: slectedSize.color_id
        }
        handleAddCart(data);
        // // Set the updated product details in the context
        // setAddToCart(updatedProductDetails);
        // console.log(updatedProductDetails);
        // // Use the cart context to add the product to the cart
        // const { addToCart } = useCartContext();
        // addToCart(updatedProductDetails);

    }

    // console.log(cartValue);
    // console.log(addToCart);
    // console.log("slectedColor", slectedColor);
    // console.log("slectedSize", slectedSize);
    // console.log("productAlldetail",productAlldetail);
    useEffect(() => {
        // handleSelectedColorData("brown")
    }, [slug])
    console.log("slectedColor  " + slectedColor);
    const fetchproductDetail = async () => {
        // alert('qwert')
        try {
            const response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=1&page=1&slugs=${slug}`);
            if (response) {
                console.log('product detail' + JSON.stringify(response))
                setGetProductDetail(response.list[0])
                setAllImages(response.list[0]?.colors?.[0]?.image)
                setAllSizeData(response.list[0]?.colors?.[0].size)
                setMainImageSrc(response?.list?.[0]?.colors?.[0]?.image?.[0]?.images)
                // setMainImageSrc(response?.list?.[0]?.colors?.[0]?.image?.[0]?.images);
                setPrice(response?.list?.[0]?.colors?.[0]?.product_price)
                setSelectedColor(response?.list?.[0]?.colors?.[0]?.color_name)
                setSelectedSize(response?.list?.[0]?.colors?.[0]?.size?.[0])
                // setSelectedColor()
                setProductID(response.list[0].product_Id)
                console.log(response.list);
                fetchProducts(response.list[0].category_id)
                fetchReviews(response.list[0].product_Id)

            }
        } catch (error) {
            console.log('Error fetching product category:', error);
        }
    };
    const fetchProducts = async (id) => {
        // setLoading(true);
        console.log(id);
        try {
            const current = '1'
            const limit = '5'
            let response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${limit}&page=${current}&category_id=${id}`);


            if (response && response.list) {
                console.log('category fetch' + response.list);
                const newrecommend = response.list.filter((v) => v.category_id == id)
                console.log('newrecommend' + newrecommend);
                setRecommendedProduct(newrecommend)
                // setProducts(response.list);

                // If you have pagination information from the API, such as total number of items:
                // const totalItems = response.total || 0;

                // setPagination((prevPagination) => ({
                //   ...prevPagination,
                //   total: totalItems,
                // }));
            }
        } catch (error) {
            console.log('Error fetching product category:', error);
        } finally {
            //   setLoading(false);
        }
    };
    const fetchReviews = async (productId) => {
        setLoading(true);
        try {
            const response = await get(`/review.php?action=fetch&product_id=${productId}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`);
            console.log(response);
            setReviews(response.data);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    console.log(getProductDetail);
    console.log(allImage);
    console.log("slectedSize" + JSON.stringify(slectedSize));
    return (
        <>
            <Breadcrumbs />

            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-1 order-2 order-md-1 product-detail-left-grid'>
                            {allImage?.map((src, index) => (
                                <div
                                    key={index}
                                    className={`outer-product-detail ${mainImageSrc === src.images ? "selected-product-img" : ""}`}
                                    onClick={() => handleThumbnailClick(src.images)}
                                >
                                    <img
                                        className='inner-product-detail'
                                        src={src.images}
                                        alt={`Product Image ${index + 1}`}
                                        loading='lazy'
                                    />
                                </div>
                            ))}

                        </div>
                        <div className='col-md-6 order-1 order-md-2 product-detail-middle-grid'>
                            <div className='middle-grid-product-detail'>
                                <img className='middle-grid-img' src={mainImageSrc} alt='' loading='lazy' />
                            </div>
                        </div>
                        <div className='col-md-5 order-3 order-md-3 product-detail-right-grid'>
                            <div>
                                <h4>{getProductDetail?.product_name} </h4>
                                {/* <span className='style-no'> Style No SG219122</span> */}
                                <div>
                                    <label className='text-muted pr-1 mt-2'>M.R.P</label> <label className='product-details-price'>₹{price}</label>
                                </div>
                                <span className='text-muted inclusive-taxes'>
                                    inclusive of all taxes
                                </span>

                                <hr />
                            </div>
                            <div className='main-color-list row pr-4 mt-2'>
                                <h5 className='mb-3'>Select Color</h5>

                                {getProductDetail?.colors?.map((v, i) => (
                                    <div className='col-md-2 col-sm-2 col-2'>
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered  d-flex justify-content-center align-items-center flex-column pb-2"
                                            bis_skin_checked={1}
                                            onClick={() => { handleSelectedColorData(v) }}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1152}
                                                type="radio"
                                            />{" "}

                                            <div
                                                className={`swatch-option border border-[#F0F0F0] color w-5 h-5 mb-2 mr-3 ${slectedColor == v.color_name ? "product-detail-page-default-selected-color" : ""}`}
                                                data-am-js="swatch-item"
                                                option-type={1}
                                                option-id={1152}
                                                option-label="Brown"
                                                option-tooltip-thumb=""
                                                option-tooltip-value="#702219"
                                                data-option-type={1}
                                                data-option-id={1152}
                                                data-option-label="Brown"
                                                data-option-tooltip-thumb=""
                                                data-option-tooltip-value="#702219"
                                                style={{ backgroundColor: v.color_code }}
                                                bis_skin_checked={1}
                                            />
                                            <div className="am-shopby-swatch-label" bis_skin_checked={1}>
                                                {" "}
                                                <span className="label">{v.name}</span> <span className="count"></span>{" "}
                                            </div>{" "}
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <hr />
                            <div>
                                <h5>Select Size</h5>
                                <div className='product-detail-size-list mt-3'>

                                    {allSizeData?.map((v, i) => (
                                        <div
                                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                                            onClick={() => { handleSelectedSize(v) }}
                                            bis_skin_checked={1}
                                        >
                                            {" "}
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5117}
                                                type="checkbox"
                                            />{" "}
                                            <div
                                                className={`swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] ${slectedSize?.size_id == v.size_id ? "product-detail-page-default-selected-size" : ""} `}
                                                data-am-js="swatch-item"
                                                option-type={0}
                                                option-id={5117}
                                                option-label={v}
                                                option-tooltip-thumb=""
                                                option-tooltip-value=""
                                                data-option-type={0}
                                                data-option-id={5117}
                                                data-option-label={v}
                                                data-option-tooltip-thumb=""
                                                data-option-tooltip-value=""
                                                style={{}}
                                                bis_skin_checked={1}
                                            >
                                                {" "}
                                                {v.product_size}
                                            </div>{" "}
                                            {/* </a> */}
                                        </div>
                                    ))}

                                </div>
                                <hr />
                            </div>
                            <div className='main-add-to-cart-buy-now'>


                                <button className='product-detail-buy-now' onClick={() => handleaddToCart(getProductDetail)}>
                                    ADD TO CART
                                </button>


                            </div>
                            <section>
                                <div className='row my-3 purchase-protection-row'>
                                    <div className='col-6   mb-2'>
                                        <div className='d-flex gap-2 purchase-protection-grid'>
                                            <svg
                                                className="flex-shrink-0"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                {" "}
                                                <circle cx="11.9989" cy="11.9989" r="10.4091" stroke="#223330" />{" "}
                                                <path
                                                    d="M15.8182 6.54688H17.4545L7.63636 17.456H6L15.8182 6.54688Z"
                                                    fill="#223330"
                                                />{" "}
                                                <circle cx="8.72869" cy="8.72869" r="1.68182" stroke="#223330" />{" "}
                                                <circle cx="15.2717" cy="15.2717" r="1.68182" stroke="#223330" />{" "}
                                            </svg>
                                            <span> 100% Purchase Protection</span>
                                        </div>
                                    </div>
                                    <div className='col-6 purchase-protection-grid mb-2'>
                                        <div className='d-flex gap-2'>
                                            <svg class="flex-shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="11.9989" cy="11.9989" r="10.4091" stroke="#223330"></circle> <path d="M4.15234 9.33594L6.35473 12.326L9.78286 10.5769" stroke="#223330" stroke-miterlimit="10"></path> <path d="M6.4375 12.0011C6.4375 8.92841 8.92841 6.4375 12.0011 6.4375C15.0739 6.4375 17.5648 8.92841 17.5648 12.0011C17.5648 15.0739 15.0739 17.5648 12.0011 17.5648" stroke="#223330" stroke-linejoin="round"></path> </svg>
                                            <span> 48 hours easy returns.</span>
                                        </div>
                                    </div>
                                    <div className='col-6 purchase-protection-grid mb-2'>
                                        <div className='d-flex gap-2'>
                                            <svg
                                                className="flex-shrink-0"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                {" "}
                                                <circle cx="11.9989" cy="11.9989" r="10.4091" stroke="#223330" />{" "}
                                                <path
                                                    d="M14.0197 9.20548L14.1353 9.52383L14.4738 9.53457L19.0167 9.67866C19.4856 9.69354 19.6777 10.2879 19.3061 10.5744L15.7219 13.3382L15.4516 13.5466L15.5473 13.8743L16.8142 18.2129C16.9453 18.6621 16.4427 19.0292 16.0547 18.7677L12.2813 16.2239L12.0018 16.0355L11.7223 16.2239L7.94884 18.7677C7.56083 19.0292 7.05824 18.6621 7.1894 18.2129L8.45628 13.8743L8.55196 13.5466L8.28164 13.3382L4.69742 10.5744C4.32587 10.2879 4.51795 9.69354 4.98689 9.67866L9.52976 9.53457L9.86828 9.52383L9.98388 9.20548L11.5318 4.94273C11.6913 4.50361 12.3123 4.50361 12.4718 4.94273L14.0197 9.20548Z"
                                                    stroke="#223330"
                                                />{" "}
                                            </svg>

                                            <span> Assured Quality</span>
                                        </div>
                                    </div>
                                    <div className='col-6 purchase-protection-grid mb-2'>
                                        <div className='d-flex gap-2'>
                                            <svg
                                                className="flex-shrink-0"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                {" "}
                                                <rect x="3.5" y="5.5" width={17} height={15} fill="" stroke="#223330" />{" "}
                                                <rect x="2.5" y="3.5" width={19} height={6} fill="" stroke="#223330" />{" "}
                                                <line x1={14} y1="17.5" x2={18} y2="17.5" stroke="#223330" />{" "}
                                            </svg>

                                            <span> Free shipping*</span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <Accordion>


                                    <Accordion.Item eventKey="1">
                                        <hr />
                                        <Accordion.Header className="container">
                                            {" "}
                                            <h6 className=" accordion-title"> Product Details</h6>
                                        </Accordion.Header>
                                        <Accordion.Body className="container">
                                            <div>
                                                <div className='product-detail-page-product-detail-accordion'>
                                                    <label> Style No : &nbsp; {getProductDetail?.product_Id} </label>
                                                    <label> Color:&nbsp; {slectedColor} </label>
                                                    {/* <label>Fabric: Jacquard </label>
                                                    <label>Work: Print </label>
                                                    <label>Closure: Front Buttons </label>
                                                    <label>Neck Line: Mandarin Collar </label>
                                                    <label> Sleeves: Full Sleeves </label> */}
                                                </div>
                                                <p className='mt-2'>
                                                    There may be slight color variation depending on the screen one is looking through and once you receive the product.
                                                    Inclusive of all taxes

                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <Accordion.Item eventKey="2">
                                        <hr />
                                        <Accordion.Header className="container">
                                            {" "}
                                            <h6 className=" accordion-title"> Style &amp; Fit Tips</h6>
                                        </Accordion.Header>
                                        <Accordion.Body className="container">
                                            <div>
                                                <div className='product-details-faqs-accordion mt-2'>
                                                    <div className='product-details-faqs-title'> Style Tips:</div>
                                                    <p className='product-details-faqs-para'>
                                                        Opt for subtle accessories like a simple watch or a sleek bracelet.
                                                    </p>
                                                </div>
                                                <div className='product-details-faqs-accordion'>
                                                    <div className='product-details-faqs-title'> Fit Tips:</div>
                                                    <p className='product-details-faqs-para'>
                                                        The sleeves of both the kurta and jacket should fall just at the wrist bone or slightly above.


                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item> */}
                                    <Accordion.Item eventKey="3">
                                        <hr />
                                        <Accordion.Header className="container">
                                            {" "}
                                            <h6 className=" accordion-title"> Shipping and Return</h6>
                                        </Accordion.Header>
                                        <Accordion.Body className="container">
                                            <div className='my-2'>
                                                <p className='product-details-faqs-para'>
                                                    We deliver our products around the world and offer different shipping options as applicable.
                                                    For more information, please feel free to contact our customer care service at katariadigital@gmail.com or +91 91579 12244

                                                </p>

                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>



                                    <hr />
                                </Accordion>
                            </section>
                        </div>
                    </div>

                </div>
            </section>

            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            itemSelectedColor: "var(--primaryColor)",
                            inkBarColor: "var(--primaryColor)",
                            titleFontSize: "19px",

                        },
                    },
                }}
            >
                <CustomersReviews reviews={reviews} productID={productID} />
            </ConfigProvider>
            <section className='recommendaton-section'>
                <div className='container'>
                    <h5 className=' similar-product-title'>Similar Products</h5>

                    <div className=''>

                        <Swiper navigation={true} pagination={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Navigation, Pagination]} className="mySwiper py-5">

                            {recommendedProduct?.map((v, i) => (
                                <SwiperSlide>
                                    <Link to={`/product-detail-page/${v.slug}`}>
                                        <RecommendationCard img={v.colors?.[0]?.image?.[0]?.images} id={v.id} price={v.colors?.[0]?.product_price} name={v.product_name} />
                                        {/* pName={v.product_name}
                          pImage={v.colors?.[0]?.image?.[0]?.images }
                          pImagesSecond={v.colors?.[0]?.image?.[1]?.images }
                          pPrice={v.colors?.[0]?.product_price || 'N/A'} */}
                                    </Link>
                                </SwiperSlide>
                            ))}


                        </Swiper>

                    </div>


                </div>
            </section>




            <Footer />
        </>
    )
}

export default ProductDetailsPages