import React from 'react'
import Footer from '../Footer'

const RefundPolicy = () => {
    return (
        <>
            <div className='container'>
                <h2 className='mt-5 text-center'>Refund Policy</h2>
                <p>100% Purchase Protection | Happiness Guarantee | 7 Days Easy Exchange</p>

                <p>We are committed to ensuring 100% purchase protection for customers by offering genuine products, secure payments, and easy exchange for items shopped on <a href="https://www.shreeman.in">www.shreeman.in</a>.</p>

                <p>We stand by our product 100%, no questions asked. If you have a problem, we will solve it. Just reach out to our customer happiness team. We will review and honor the exchange up to 7 days from the time you receive the items.</p>

                <h3>Eligibility for Exchange</h3>
                <p>Items are only eligible for exchange if they are in their original condition with all tags and labels attached, and have not been washed, worn, altered, or damaged.</p>

                <h3>Exchange Process</h3>
                <p>To initiate an exchange, please contact our customer service team within 7 days of receiving the item.</p>
                <p>Exchanges are subject to availability, and we cannot guarantee that the size you need will be in stock. If the size you need is not available, we will issue you a store credit.</p>
                <p>The customer is responsible for shipping the item back to us for exchange. We recommend using a tracked and insured shipping service to ensure the safe arrival of the item.</p>
                <p>Once an article is received at our warehouse, it will undergo a quality check for eligibility of exchange. If approved or rejected, you will be notified by email.</p>
                <p>Once we receive the exchange item, we will process the exchange within 5-7 business days. The exchanged item will be shipped back to you via our standard shipping method.</p>

                <h3>SALE Items Purchase</h3>
                <p>Returns and exchanges are not available for sale products purchased. This policy ensures we can offer you the best possible prices. We recommend reviewing size and descriptions carefully before your purchase. If you have any questions about sizing or fit, please contact us before placing your order.</p>

                <h3>Can I Exchange Products Purchased During the Sale?</h3>
                <p>Products purchased during or on sale will not be eligible for exchange. Made-to-order (MTO) or customized items cannot be exchanged or returned. Altered garments, customized styles, and stitched blouses are not returnable.</p>

                <h3>Can I Exchange Products Purchased During FaceTime or Skype Call?</h3>
                <p>Products purchased during or on FaceTime or Skype calls are only eligible for exchange. No refunds are provided for such purchases as each product is shown to customers properly.</p>

                <h3>How can I claim my exchange?</h3>
                <p>For exchange requests, kindly login to your account, click "My Exchanges," select the order number, and place a request. The 5-7 business days will be taken to process the refund and credit the amount to the customer's bank account. You can also escalate an exchange request by emailing us at <a href="mailto:shreemandigital@gmail.com">shreemandigital@gmail.com</a> with the reason for the exchange within 24-48 hours from the time the product is delivered. Our service manager will reply to you within 24 hours. You can also exchange items at our Shreeman stores in Gujarat, India.</p>

                <h3>Additional Information</h3>
                <p>Dispatch time will be 7-10 days depending on the product.</p>
                <p>Products which fall under made-to-measure and customization categories are not applicable for refund or exchange.</p>
            
                <h3>Delivery Charges</h3>
                <p>Delivery charges will be deducted from your total payment. Delivery charges are non-refundable.</p>

            </div>
            <Footer />
        </>
    )
}

export default RefundPolicy