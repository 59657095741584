import React, { useEffect, useState } from 'react';
import { Form, Input, Tabs, Rate, Button, Modal, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CiStar } from 'react-icons/ci';
import { IoMdStar } from 'react-icons/io';

import '../Css/CustomersReviews.css';
import { get, post } from '../apiService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import CustomerReviewCard from './CustomerReviewCard';

const CustomersReviews = ({ productID, reviews }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState([])
    const profile = JSON.parse(localStorage.getItem('profile'))
    // const [reviews, setReviews] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Reviews ()',
            children: '',
        },
        // {
        //   key: '2',
        //   label: 'Questions ()',
        //   children: '',
        // },
    ];
    const [form] = Form.useForm();
    //   const [rating, setRating] = useState(0);

    const onFinish = async (values) => {
        console.log(values);
        if (!profile?.user_id) {
            message.error('Please Login First.');
            return;
        }
        const formData = new FormData();
        setLoading(true); // Start loading

        try {
            // Append form data
            formData.append('review', values.reviewContent);
            formData.append('stars', values.rating);
            formData.append('action', 'add');
            formData.append('user_id', profile.user_id);
            formData.append('product_id', productID);
            formData.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH')

            // Simulate API request
            const response = await post('/review.php', formData); // Replace with your actual API endpoint
            if (response) {
                // Handle success response
                console.log('Review submitted successfully:', response);
            }
        } catch (error) {
            console.log('Error posting review:', error);
        } finally {
            setLoading(false); // Stop loading after submission
            handleOk(); // Close the modal
        }
    };
    const totalStars = reviews?.reduce((sum, review) => {
        const stars = parseInt(review.stars, 10);
        return stars > 0 ? sum + stars : sum;
    }, 0);

    const numberOfReviews = reviews?.reduce((count, review) => {
        return parseInt(review.stars, 10) > 0 ? count + 1 : count;
    }, 0);

    const averageStars = numberOfReviews > 0 ? (totalStars / numberOfReviews) : 0;

    // console.log('Average Stars:', averageStars);
    // useEffect(() => {
    //     fetchReviews();
    // }, []);

    // const fetchReviews = async (current, pageSize) => {
    //     setLoading(true);
    //     try {
    //         const response = await get(`/review.php?action=fetch&product_id=${productID}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`);
    //         console.log(response);
    //         setReviews(response.data);

    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <>
            <section className='section-cr-main'>
                <div className='container'>
                    <h5>Customer Reviews</h5>
                    <div className='cr-second-div'>
                        <div></div>
                        <div>
                            <div className='cr-stars'>
                                <label>
                                    <div className='be-the-first-star text-muted justify-content-center d-flex mb-1'>
                                        <Rate value={averageStars} />
                                    </div>
                                </label>
                                {/* <label><CiStar /></label>
                                <label><CiStar /></label>
                                <label><CiStar /></label>
                                <label><CiStar /></label>
                                <label><CiStar /></label> */}
                            </div>
                        </div>
                        {/* {profile && (
                            <div className='cr-modal-button'>
                                <button onClick={showModal}>Write a Review</button>
                            </div>
                        )} */}

                    </div>
                    <div>
                        <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
                    </div>
                    <div className='mb-1'>

                        {reviews.length === 0 && (

                            <div>
                                <div className='be-the-first-star text-muted justify-content-center d-flex mb-1'>
                                    <label><IoMdStar /></label>
                                    <label><IoMdStar /></label>
                                    <label><IoMdStar /></label>
                                    <label><IoMdStar /></label>
                                    <label><IoMdStar /></label>
                                </div>
                                <span className='d-block text-center be-the-first'>Be the first to review this item</span>
                            </div>
                        )}

                        <Swiper
                            spaceBetween={30}
                            // centeredSlides={true}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            // }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {reviews?.map((v, i) =>
                            (
                                <SwiperSlide>
                                    <CustomerReviewCard description={v.reviews} rate={v.stars} name={v.user_name} />
                                </SwiperSlide>
                            )
                            )}
                        </Swiper>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                title="Write a Review"
                onOk={() => form.submit()} // Trigger form submission when OK is clicked
                confirmLoading={loading} // Show loading spinner on the "OK" button
                onCancel={handleCancel}
            >
                <div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish} // Handle form submission
                    >
                        <Form.Item
                            name="rating"
                            label="Rating"
                            rules={[{ required: true, message: 'Please provide a rating' }]}
                        >
                            <Rate onChange={(value) => setRating(value)} value={rating} />
                        </Form.Item>
                        <Form.Item
                            name="reviewTitle"
                            label="Review Title"
                            rules={[{ required: true, message: 'Please provide a review title' }]}
                        >
                            <Input placeholder="Give your review a title" />
                        </Form.Item>
                        <Form.Item
                            name="reviewContent"
                            label="Review"
                            rules={[{ required: true, message: 'Please share your experience' }]}
                        >
                            <Input.TextArea rows={6} placeholder="How was your overall experience?" />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>


        </>
    );
}

export default CustomersReviews;
