import React, { useEffect, useState } from 'react';
import "../../Css/ProfileEdit.css";
import axios from 'axios';
import { Button, Form, Input, Modal, message } from 'antd';
import { post } from '../../apiService';

const ProfileEdit = () => {
    const [form] = Form.useForm();
    const tokenid = localStorage.getItem("access_token");
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileData, setProfileData] = useState({
        username: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
        getProfile();

    }, []);

    const getProfile = () => {
        const storedProfileData = JSON.parse(localStorage.getItem('profile'));
        console.log("Stored profile data:", storedProfileData); // Add this line to check stored profile data
        form.setFieldsValue({
            username: storedProfileData?.username,
            email: storedProfileData.email,
            phone: storedProfileData.phone
        });
        if (storedProfileData) {
            setProfileData(storedProfileData);
        }
    };


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        setLoading(true); // Set loading to true when OK is clicked

        form.validateFields().then(values => {
            console.log(values);

            const formDataToSend = new FormData();
            formDataToSend.append('user_name', values.username);
            formDataToSend.append('user_email', values.email);
            formDataToSend.append('user_phone', values.phone);
            formDataToSend.append('action', "update");
            formDataToSend.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');

            console.log(formDataToSend);

            post('/user.php', formDataToSend)
                .then(response => {
                    console.log('Data Updated successfully:', response);
                    message.success('Data Updated successfully');
                    localStorage.setItem('profile', JSON.stringify(values));
                    setProfileData(values);
                    setIsModalOpen(false); // Close the modal after successful update
                })
                .catch(error => {
                    console.error('Error updating data:', error);
                })
                .finally(() => {
                    setLoading(false); // Always reset loading state after operation
                });
        }).catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false); // Reset loading state if form validation fails
        });
    };

    console.log(profileData);
    return (
        <section className='ProfileEdit'>
            <div className='ProfileEdit-con'>
                <div className='ProfileEdit-con-heading'>
                    <h2>Edit Profile Details</h2>
                </div>
                <div className='ProfileEdit-con-inputs'>
                    <Form layout="vertical" initialValues={profileData} form={form}>
                        <Form.Item name="username" label="First Name">
                            <Input type="text" placeholder="Enter Name" disabled />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input type="text" placeholder="Enter Email" disabled />
                        </Form.Item>
                        <Form.Item name="phone" label="Mobile Number">
                            <Input type="number" placeholder="Enter Contact" disabled />
                        </Form.Item>
                    </Form>
                </div>
                <div className='ProfileEdit-btn'>
                    <button onClick={showModal}>
                        Update Profile
                    </button>
                </div>
            </div>

            <Modal
                visible={isModalOpen}
                onCancel={handleCancel}
                confirmLoading={loading} // Attach loading state to OK button
                onOk={handleOk}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="username"
                        label="First Name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input type="text" placeholder="Enter Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="text" placeholder="Enter Email" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Mobile Number"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input type="number" placeholder="Enter Contact" />
                    </Form.Item>
                </Form>
            </Modal>

        </section>
    );
};

export default ProfileEdit;
