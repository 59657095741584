import React, { useEffect, useLayoutEffect, useState } from 'react'
import Accordion from "react-bootstrap/Accordion";
import '../Css/Product.css';
import Breadcrumbs from '../Components/Breadcrumbs';
import ClothCard from '../Components/ClothCard';
import Footer from '../Components/Footer';
import { Link, useParams } from 'react-router-dom';
import FilterSort from '../Components/FilterSort';
import ProductData from "../Assert/ProductData";
import { get } from '../apiService';
import { Pagination, Slider, Spin } from 'antd';
import axios from 'axios';

const Product = () => {
  const { slug } = useParams()
  const [showGroomList, setShowGroomList] = useState(false)
  const [loading, setLoading] = useState(false)
  console.log(slug);
  const cards = Array.from({ length: 10 });
  const [Products, setProducts] = useState([])
  const [allcategory, setAllCategory] = useState([])
  const [productAllImages, setProductAllImages] = useState([])
  const [filterColor, setFilterColor] = useState([])
  const [filterSizes, setFilterSizes] = useState([])
  const [priceRange, setPriceRange] = useState([0, 25000]);
  
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    fetchColor()
    fetchSizes()
  }, [])
  // fet

  useEffect(() => {

    const category = localStorage.getItem('category');

    // Check if 'category' exists and parse it to an array
    if (category) {
      const categoryArray = JSON.parse(category);
      const getCategory = categoryArray.find(v => v.category_slugs === slug);
      setAllCategory(categoryArray);
      console.log(getCategory);

      if (slug === 'all') {
        // Fetch all products
        fetchProducts();
      } else if (slug === 'newArrival') {
        // Fetch new arrivals without passing category_id
        fetchProducts('newArrival');
      } else if (getCategory) {
        // Fetch products by category
        fetchProducts(getCategory.category_Id);
      }
    }
  }, [pagination.current, pagination.pageSize, slug]);


  const fetchProducts = async (id) => {
    setLoading(true);
    try {
      let response;

      if (id === undefined) {
        // Fetch all products
        response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}`);
      } else if (id === 'newArrival') {
        // Fetch new arrivals, exclude category_id
        response = await get(`/product.php?action=fetchNewArival&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&page=${pagination.current}&limit=${pagination.pageSize}`);
      } else {
        // Fetch products by category
        response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}&category_id=${id}`);
      }


      if (response && response.list) {
        console.log(response.list);
        setProducts(response.list);

        // If you have pagination information from the API, such as total number of items:
        const totalItems = response.total || 0;

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalItems,
        }));
      }
    } catch (error) {
      console.log('Error fetching product category:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (newPagination) => {
    const { current, pageSize } = newPagination
    setPagination((prevData) => (
      {
        ...prevData,
        current,
        pageSize
      }
    ))
  }

  const fetchColor = async () => {
    try {
      const response = await get(`/product.php?action=fetchColors&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`)
      setFilterColor(response)
    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const fetchSizes = async () => {
    try {
      const response = await get(`/product.php?action=fetchSizes&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`)
      setFilterSizes(response)
    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const fetchPriceRangeProducts = async () => {
    // alert('qwert')
    setLoading(true);
    try {
      const response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&price_from=${priceRange[0]}&price_to=${priceRange[1]}&page=${pagination.current}&limit=${pagination.pageSize}`);
      console.log('price Products:', response);
      setProducts(response.list);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally{
      setLoading(false);
    }
  }
  const handleSliderChange = (value) => {
    // console.log(value);
    if (value[0] >= value[1]) {
      return;
    }
    setPriceRange(value);

    // setPriceRange(value);
  };

  // Fetch products when the price range changes
  // fetchProducts();

  
  return (
    <>
      <Breadcrumbs />
      <section>
        <div className='container'>
          <div className='main-product-page'>
            <div className=' product-page-filter-left'>
              <h5> FILTERS</h5>
              <Accordion>

                {/* {ServiceData.map((v,i)=>{
     return(
         <> */}
                <Accordion.Item eventKey="1">
                  <hr />
                  <Accordion.Header className="container">
                    {" "}
                    <h6 className=" accordion-title"> Price</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div>
                      {/* <div className='mb-1'>
                        <span> ₹0 - ₹83,000 <label className='text-muted'>238</label> </span>
                      </div>
                      <div className='mb-1'>
                        <span> ₹83,000 - ₹166,000 <label className='text-muted fs-8'>5</label> </span>
                      </div> */}
                      <div>
                        <Slider
                          range={{
                            draggableTrack: true,
                          }}
                          min={0}
                          max={25000}
                          defaultValue={priceRange}
                          onChange={handleSliderChange}
                        />
                        <div>
                          Selected Price Range: ₹{priceRange[0]} - ₹{priceRange[1]}
                        </div>

                        
                          <button onClick={fetchPriceRangeProducts} className="btn text-center filter-apply-btn mb-2">
                            Apply
                          </button>

                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <hr />
                  <Accordion.Header className="container">
                    {" "}
                    <h6 className=" accordion-title"> Sub Categories</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className='main-sub-categories-list'>
                      {allcategory?.map((v, i) => (
                        <Link to={`${v.category_slugs}`}>
                          <span className=''> {v.category_name}
                            {/* <label className='text-muted'> 31</label> */}
                          </span>
                        </Link>
                      ))}
                      {/* <span className=''>Men Kurta Set <label className='text-muted'> 31</label> </span>
                      <span className=''>Kurta jacket Sets <label className='text-muted'> 31</label> </span>
                      <span className=''>Sherwanis <label className='text-muted'> 2</label> </span>
                      <span className=''>Jodhpuri <label className='text-muted'> 3</label> </span>
                      <span className=''>Designer kurtas <label className='text-muted'> 31</label> </span>
                      <span className=''>Indo western Men <label className='text-muted'> 1</label> </span>
                      <span className=''>tuxedo <label className='text-muted'> 1</label> </span> */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <hr />
                  <Accordion.Header className="container">
                    {" "}
                    <h6 className=" accordion-title"> Color</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className='main-color-list row pr-4 mt-2'>
                      {filterColor.map((v, i) => (
                        <div className='col-md-2'>
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            {" "}
                            <input
                              className="input hidden"
                              name="amshopby[confi_color][]"
                              defaultValue={1152}
                              type="radio"
                            />{" "}
                            <a
                              href=""
                              rel="nofollow"
                              data-label="Brown"
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                data-am-js="swatch-item"
                                option-type={1}
                                option-id={1152}
                                option-label="Brown"
                                option-tooltip-thumb=""
                                option-tooltip-value="#702219"
                                data-option-type={1}
                                data-option-id={1152}
                                data-option-label="Brown"
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value="#702219"
                                style={{ backgroundColor: `${v.color_code}` }}
                                bis_skin_checked={1}
                              />
                              <div className="am-shopby-swatch-label hidden" bis_skin_checked={1}>
                                {" "}
                                <span className="label">Brown</span> <span className="count">1</span>{" "}
                                <span className="filter-count-label"> item </span>
                              </div>{" "}
                            </a>
                          </div>
                        </div>
                      ))}

                    </div>
                  </Accordion.Body>
                </Accordion.Item>



                <Accordion.Item eventKey="7">
                  <hr />
                  <Accordion.Header className="container">
                    {" "}
                    <h6 className=" accordion-title"> Select Size</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className='main-size-list row mt-2'>
                      {filterSizes?.map((v, i) => (
                        <div className='col-md-4'>
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            {" "}
                            <input
                              className="input hidden"
                              name="amshopby[confi_size][]"
                              defaultValue={5115}
                              type="checkbox"
                            />{" "}
                            <a
                              href="https://www..com/in/ethnic/best-man.html?confi_size=5115"
                              rel="nofollow"
                              data-label={36}
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                data-am-js="swatch-item"
                                option-type={0}
                                option-id={5115}
                                option-label={36}
                                option-tooltip-thumb=""
                                option-tooltip-value=""
                                data-option-type={0}
                                data-option-id={5115}
                                data-option-label={36}
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value=""
                                style={{}}
                                bis_skin_checked={1}
                              >
                                {" "}
                                {v.product_size}
                              </div>{" "}
                            </a>
                          </div>

                        </div>
                      ))}
                      {/* 
                      <div className='col-md-4'>
                        <div
                          className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                          bis_skin_checked={1}
                        >
                          {" "}
                          <input
                            className="input hidden"
                            name="amshopby[confi_size][]"
                            defaultValue={5116}
                            type="checkbox"
                          />{" "}
                          <a
                            href="https://www..com/in/ethnic/best-man.html?confi_size=5116"
                            rel="nofollow"
                            data-label={38}
                            className="am-swatch-link inline-block"
                          >
                            <div
                              className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                              data-am-js="swatch-item"
                              option-type={0}
                              option-id={5116}
                              option-label={38}
                              option-tooltip-thumb=""
                              option-tooltip-value=""
                              data-option-type={0}
                              data-option-id={5116}
                              data-option-label={38}
                              data-option-tooltip-thumb=""
                              data-option-tooltip-value=""
                              style={{}}
                              bis_skin_checked={1}
                            >
                              {" "}
                              38
                            </div>{" "}
                          </a>
                        </div>

                      </div>
                      <div className='col-md-4'>
                        <div
                          className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                          bis_skin_checked={1}
                        >
                          {" "}
                          <input
                            className="input hidden"
                            name="amshopby[confi_size][]"
                            defaultValue={5117}
                            type="checkbox"
                          />{" "}
                          <a
                            href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                            rel="nofollow"
                            data-label={40}
                            className="am-swatch-link inline-block"
                          >
                            <div
                              className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                              data-am-js="swatch-item"
                              option-type={0}
                              option-id={5117}
                              option-label={40}
                              option-tooltip-thumb=""
                              option-tooltip-value=""
                              data-option-type={0}
                              data-option-id={5117}
                              data-option-label={40}
                              data-option-tooltip-thumb=""
                              data-option-tooltip-value=""
                              style={{}}
                              bis_skin_checked={1}
                            >
                              {" "}
                              40
                            </div>{" "}
                          </a>
                        </div>

                      </div>
                      <div className='col-md-4'>
                        <div
                          className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                          bis_skin_checked={1}
                        >
                          {" "}
                          <input
                            className="input hidden"
                            name="amshopby[confi_size][]"
                            defaultValue={5118}
                            type="checkbox"
                          />{" "}
                          <a
                            href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                            rel="nofollow"
                            data-label={42}
                            className="am-swatch-link inline-block"
                          >
                            <div
                              className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                              data-am-js="swatch-item"
                              option-type={0}
                              option-id={5118}
                              option-label={42}
                              option-tooltip-thumb=""
                              option-tooltip-value=""
                              data-option-type={0}
                              data-option-id={5118}
                              data-option-label={42}
                              data-option-tooltip-thumb=""
                              data-option-tooltip-value=""
                              style={{}}
                              bis_skin_checked={1}
                            >
                              {" "}
                              42
                            </div>{" "}
                          </a>
                        </div>

                      </div>
                      <div className='col-md-4'>
                        <div
                          className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                          bis_skin_checked={1}
                        >
                          {" "}
                          <input
                            className="input hidden"
                            name="amshopby[confi_size][]"
                            defaultValue={5119}
                            type="checkbox"
                          />{" "}
                          <a
                            href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                            rel="nofollow"
                            data-label={44}
                            className="am-swatch-link inline-block"
                          >
                            <div
                              className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                              data-am-js="swatch-item"
                              option-type={0}
                              option-id={5119}
                              option-label={44}
                              option-tooltip-thumb=""
                              option-tooltip-value=""
                              data-option-type={0}
                              data-option-id={5119}
                              data-option-label={44}
                              data-option-tooltip-thumb=""
                              data-option-tooltip-value=""
                              style={{}}
                              bis_skin_checked={1}
                            >
                              {" "}
                              44
                            </div>{" "}
                          </a>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div
                          className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                          bis_skin_checked={1}
                        >
                          {" "}
                          <input
                            className="input hidden"
                            name="amshopby[confi_size][]"
                            defaultValue={5120}
                            type="checkbox"
                          />{" "}
                          <a
                            href="https://www..com/in/ethnic/best-man.html?confi_size=5117"
                            rel="nofollow"
                            data-label={46}
                            className="am-swatch-link inline-block"
                          >
                            <div
                              className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                              data-am-js="swatch-item"
                              option-type={0}
                              option-id={5120}
                              option-label={46}
                              option-tooltip-thumb=""
                              option-tooltip-value=""
                              data-option-type={0}
                              data-option-id={5120}
                              data-option-label={46}
                              data-option-tooltip-thumb=""
                              data-option-tooltip-value=""
                              style={{}}
                              bis_skin_checked={1}
                            >
                              {" "}
                              46
                            </div>{" "}
                          </a>
                        </div>

                      </div> */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* </>
     )
   })} */}

                <hr />
              </Accordion>
              <div className="px-3 py-3 flex flex-col" bis_skin_checked={1}>
                <div className="flex flex-col pb-8" bis_skin_checked={1}>
                  {" "}
                  <svg
                    className="mx-auto empty:bg-[#E2E8F0] empty:h-[50px]"
                    width={50}
                    height={51}
                    viewBox="0 0 50 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <g clipPath="url(#clip0_2_12534)">
                      {" "}
                      <path
                        d="M8.49 26.53L4.5 26.5V22"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                      <path
                        d="M4.5 6V0.5H31.5C32.6046 0.5 33.5 1.39543 33.5 2.5V26.59L19.66 26.56"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                      <path
                        d="M29.0239 12.4888L24.5994 11.6288C24.5994 11.6288 28.9325 4.15056 29 4L27.18 5.33924L18.1911 12.6123C17.9975 12.7784 18.0916 13.0803 18.3574 13.132L22.7819 13.992C22.7819 13.992 19.5667 19.9707 19.5462 20.1546L18.1911 22.5001L29.1895 13.012C29.3796 12.8417 29.2897 12.5405 29.0239 12.4888Z"
                        fill="black"
                      />{" "}
                      <path d="M12 8.5H2" stroke="black" strokeMiterlimit={10} />{" "}
                      <path d="M12 12.5H-5" stroke="black" strokeMiterlimit={10} />{" "}
                      <path d="M12 16.5H3" stroke="black" strokeMiterlimit={10} />{" "}
                      <path d="M38.21 26.5901H33.5" stroke="black" strokeMiterlimit={10} />{" "}
                      <path
                        d="M33.0901 6.33008H39.1146C39.7246 6.33008 40.3012 6.60842 40.6806 7.08601L48.2791 16.6506C48.7006 17.1812 48.9301 17.839 48.9301 18.5167V26.5901"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                      <path
                        d="M36.5701 6.84009V15.7201C36.5701 16.8247 37.4655 17.7201 38.5701 17.7201H48.9301"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                      <path
                        d="M42.29 30.5501C44.5488 30.5501 46.38 28.719 46.38 26.4601C46.38 24.2013 44.5488 22.3701 42.29 22.3701C40.0311 22.3701 38.2 24.2013 38.2 26.4601C38.2 28.719 40.0311 30.5501 42.29 30.5501Z"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                      <path
                        d="M12.1901 30.5501C14.4489 30.5501 16.2801 28.719 16.2801 26.4601C16.2801 24.2013 14.4489 22.3701 12.1901 22.3701C9.93125 22.3701 8.1001 24.2013 8.1001 26.4601C8.1001 28.719 9.93125 30.5501 12.1901 30.5501Z"
                        stroke="black"
                        strokeMiterlimit={10}
                      />{" "}
                    </g>{" "}
                    <defs>
                      {" "}
                      <clipPath id="clip0_2_12534">
                        {" "}
                        <rect
                          width="54.43"
                          height="31.05"
                          fill="white"
                          transform="translate(-5)"
                        />{" "}
                      </clipPath>{" "}
                    </defs>{" "}
                  </svg>
                  <p className=" text-center">Enjoy Free Shipping</p>{" "}
                  <a
                    className="w-fit mx-auto cmsb631-inline-block bg-black text-white py-2 px-2 hover:text-white text-center"
                    href="#"
                    onclick="plpBanner('Enjoy Free Shipping')"
                  >
                    KNOW MORE
                  </a>
                </div>
                <div className="flex flex-col" bis_skin_checked={1}>
                  {" "}
                  <svg
                    className="mx-auto empty:bg-[#E2E8F0] empty:h-[50px]"
                    width={51}
                    height={50}
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path
                      d="M6.5 25C6.5 14.5066 15.0066 6 25.5 6C35.9934 6 44.5 14.5066 44.5 25C44.5 35.4934 35.9934 44 25.5 44C21.4632 44 17.7204 42.7411 14.6429 40.5944"
                      stroke="#0F0D1A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />{" "}
                    <path d="M3.5 22L6.26923 25L9.5 22.5" stroke="black" />{" "}
                    <path
                      d="M25.6765 34L17.2795 29.2084C16.7975 28.9334 16.5 28.4211 16.5 27.8662V21.2364M25.6765 34L33.7433 29.2127C34.2124 28.9343 34.5 28.4292 34.5 27.8837V20.9091M25.6765 34V26.1455M16.5 21.2364L25.6765 26.1455M16.5 21.2364L21.6618 18.2909M34.5 20.9091L26.4374 16.4234C25.965 16.1606 25.3897 16.1637 24.9201 16.4316L21.6618 18.2909M34.5 20.9091L30.0882 23.5273M25.6765 26.1455L30.0882 23.5273M21.6618 18.2909L30.0882 23.5273"
                      stroke="#0F0D1A"
                    />{" "}
                  </svg>
                  <p className=" text-center">48 Hours Of Easy Returns</p>{" "}
                  <a
                    className="w-fit mx-auto cmsb631-inline-block bg-black text-white py-2 px-2 hover:text-white"
                    href="#"
                    onclick="plpBanner('48 HOURS OF EASY RETURNS')"
                  >
                    KNOW MORE
                  </a>
                </div>
              </div>

            </div>
            <div className='product-page-filter-right '>
              {/* <h5> Groomsmen Outfits <span className='text-muted fs-6'>76 items</span> </h5> */}
              {/* <div className='main-groosmen-outfits my-3'>
                <div className='first-groosmen-outfits'>
                  <div onClick={() => { setShowGroomList(!showGroomList) }}>
                    <label>Occasion</label>

                  </div>
                  <div onClick={() => { setShowGroomList(!showGroomList) }}>
                    <label> Select Size</label>
                  </div>
              
                </div>
                <div>
                  <div className='sort-by'>
                    <label for="cars">Sort By:</label>
                    <select name="cars" id="cars">
                      <option value="volvo">Trending</option>
                      <option value="saab">Best Seller</option>
                      <option value="audi">Beggest Saving</option>
                    </select>

                  </div>
                </div>
              </div> */}
              <div className={`shadow dropdown-groosmen-outfits mt-2 ${showGroomList ? "" : "hidden"}`}>
                <div className='groosmen-outfits-option'>
                  <div className='dropdown-inner-list-groosmen-outfits'>
                    <div> Weeding <label className='text-muted'> 22</label></div>
                  </div>
                  <div className='dropdown-inner-list-groosmen-outfits'>
                    <div> Weeding <label className='text-muted'> 22</label></div>
                  </div>
                </div>
              </div>
              <Spin spinning={loading}>
                <div className="row">
                  {Products?.map((v, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-clothCard mb-5" key={index}>
                      <Link to={`/product-detail-page/${v.slug}`}>
                        <ClothCard
                          pName={v.product_name}
                          pImage={v.colors?.[0]?.image?.[0]?.images}  // First image
                          pImagesSecond={
                            v.colors?.[0]?.image?.[1]?.images          // Check if the second image exists
                              ? v.colors[0].image[1].images            // Use the second image if it exists
                              : v.colors?.[0]?.image?.[0]?.images      // Otherwise, use the first image
                          }
                          pPrice={v.colors?.[0]?.product_price || 'N/A'}
                        />
                      </Link>
                    </div>
                  ))}

                </div>
              </Spin>
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(page, pageSize) => handleTableChange({ current: page, pageSize })}
                showSizeChanger
                pageSizeOptions={['10', '20', '50', '100']}
                className="user-list-pagination"
              />
              <div className='my-4'>
                <h4>Designer Ethnic Wear for The Best Man</h4>
                <p className='designer-ethnic-wear'>
                  Looking for the perfect outfit for your Yaar Ki Shaadi? We got you covered. Kataria Fashion has the best collection of designer ethnic men’s wear that will make you look like the most handsome best man at your brother’s wedding. Men’s fashion has come a long way in the past years. From plain kurtas to fancy silhouettes, men’s fashion trends have become more experimental and fun. It is time to ditch the boring blacks and blues and jump into a world of lively hues this season.

                </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <FilterSort />
      <Footer />
    </>
  )
}

export default Product