import React from 'react'
import '../Css/ShopCollection.css'
import readyToShip from "../Assert/readytoShip.jpg";
import newArrivals from "../Assert/newArrivals.jpg";
import bestSelling from "../Assert/bestSelling.jpg";
import { Link } from 'react-router-dom';

const ShopCollection = () => {
    return (
        <>
            <section className='mt-1 mb-4'>
                <div className='container'>
                    {/* <h4 className='text-center mb-4'> Shop by Collection</h4> */}
                    <div className='row'>
                        <div className='col-md-4'>
                            <Link to='/product/all'>
                                <div className='main-shop-by-collection'>
                                    <div className='image-container ready-to-ship-images '>
                                        <img src={readyToShip} />
                                    </div>
                                    <div className='inner-shop-by-collection'>
                                        <h3 className='shop-by-collection-title'> Ready To Ship </h3>
                                        {/* <span className='shop-by-collection-btn'> <label> Show Now</label> </span> */}
                                    </div>
                                </div>
                            </Link>



                        </div>
                        <div className='col-md-4'>
                            <Link to='/product/all'>
                                <div className='main-shop-by-collection'>
                                    <div className='image-container best-selling-menswear'>
                                        <img src={bestSelling} />
                                    </div>
                                    <div className='inner-shop-by-collection'>
                                        <h3 className='shop-by-collection-title'> Best Selling Menswear</h3>
                                        {/* <span className='shop-by-collection-btn'> <label> Show Now</label> </span> */}
                                    </div>
                                </div>
                            </Link>


                        </div>
                        <div className='col-md-4'>
                            <Link to='/product/newArrival'>
                                <div className='main-shop-by-collection'>
                                    <div className='image-container new-Arrivals'>
                                        <img src={newArrivals} />
                                    </div>
                                    <div className='inner-shop-by-collection'>
                                        <h3 className='shop-by-collection-title'> New Arrivals</h3>
                                        {/* <span className='shop-by-collection-btn'> <label> Show Now</label> </span> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShopCollection